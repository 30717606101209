<script>
    import {onMount, tick} from "svelte";
    import {getSupplyDemand} from "../js/apiHandler.js";
    import {FontAwesomeIcon} from "@fortawesome/svelte-fontawesome";
    import {faArrowUpRightFromSquare, faEnvelope} from "@fortawesome/pro-solid-svg-icons";
    import slugify from "slugify";

    let activeTab = "supply"; // Current tab ("supply" or "demand")
    let supplySelectedItems = new Set(); // Selected items for supply
    let demandSelectedItems = new Set(); // Selected items for demand
    let tableData = []; // Data for the table
    let termsAccepted = {
        supply: false,
        demand: false,
    };
    let loading = true; // Loading state
    let tableContainer;
    let isOverflowing = false;
    let isScrolling = false;
    let isScrolledToRight = false;

    // Set cache duration to five minutes.
    const CACHE_DURATION = 5 * 60 * 1000;

    // In order for the data not constantly be reloaded, we will cache it.
    let cache = {
        supply: {data: null, timestamp: null},
        demand: {data: null, timestamp: null},
    };

    // Fetch data from the API
    const fetchSupplyDemandData = async () => {
        loading = true;
        const now = Date.now(); // Current timestamp

        // Check if cached data is valid
        if (
            cache[activeTab].data && // Cached data exists
            cache[activeTab].timestamp && // Timestamp exists
            now - cache[activeTab].timestamp < CACHE_DURATION // Cache is still valid
        ) {
            //console.log(`Using cached data for ${activeTab}`);
            tableData = cache[activeTab].data; // Use cached data
            loading = false;
            return; // Stop here since we're using cached data!
        }

        // Fetch fresh data if cache is invalid
        try {
            //console.log(`Fetching fresh data for ${activeTab}`);
            const data = await getSupplyDemand(10); // Call your API

            //console.log("Full API Response:", data);

            // Create a unique ID for each item based on the tab and its index.
            // We need to do this because otherwise there are weird selection issues when tabbing between the tables.
            const supplyData = data.response.supply || [];
            const demandData = data.response.demand || [];
            cache[activeTab].data = (activeTab === "supply" ? supplyData : demandData).map((item, idx) => ({
                ...item,
                id: `${activeTab}-${idx}`,
            }));
            cache[activeTab].timestamp = now; // Update timestamp
            tableData = [...cache[activeTab].data]; // Force reactivity
        } catch (error) {
            //console.error("Error fetching supply/demand data:", error);
        } finally {
            loading = false;
        }
    };

    // Switch between tabs.
    const switchTab = async (tab) => {
        activeTab = tab;
        tableData = []; // Force UI update before fetching new data.
        await tick(); // Ensure Svelte registers the change!
        await fetchSupplyDemandData();
    };

    // Toggle item selection
    const toggleSelection = (id) => {
        if (activeTab === "supply") {
            supplySelectedItems = new Set([id]);
        } else {
            demandSelectedItems = new Set([id]);
        }
    };

    // Handle the purchase/sale button click
    function handleBuySellClick() {
        const selectedItems = activeTab === "supply" ? supplySelectedItems : demandSelectedItems;
        const selectedId = [...selectedItems][0]; // Get the first (and only) selected item ID
        const selectedItem = tableData.find(item => item.id === selectedId); // Find the item in the table data

        // Navigate to the item's link!
        if (selectedItem && selectedItem.link) {
            window.open(selectedItem.link, '_blank');
        }
    }

    const checkOverflow = () => {
        if (tableContainer) {
            isOverflowing = tableContainer.scrollWidth > tableContainer.clientWidth;
            updateScrollState();
        }
    };

    const updateScrollState = () => {
        const scrollLeft = tableContainer.scrollLeft;
        const maxScrollLeft = tableContainer.scrollWidth - tableContainer.clientWidth;

        //console.log("Scroll Left:", scrollLeft);
        //console.log("Max Scroll Left:", maxScrollLeft);

        isScrolling = scrollLeft > 1;
        isScrolledToRight = scrollLeft >= maxScrollLeft - 1; // Add a small tolerance
    };

    const handleScroll = () => {
        updateScrollState();
    };

    const formatCurrency = (value) => {
        const formattedNumber = new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
        return `€ ${formattedNumber}`;
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        if (isNaN(date)) return dateString; // fallback in case parsing fails

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    onMount(() => {
        fetchSupplyDemandData();
        checkOverflow();

        // Optional: re-check on window resize
        tableContainer.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', checkOverflow);

        return () => {
            tableContainer.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', checkOverflow);
        };
    });
</script>

<style lang="scss">
  .supply-demand {

  }

  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    column-gap: 16px;
    @media (max-width: 576px) {
      column-gap: 8px;
    }

    button {
      padding: 19px 62px 17px;
      margin: 0;
      @media (max-width: 576px) {
        flex: 1;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .tab-container {
    &.isOverflowing {
      margin-left: calc(-1 * (100vw - 100%) / 2);
      margin-right: calc(-1 * (100vw - 100%) / 2);
      padding: 0 calc((100vw - 100%) / 2);
      position: relative;

      &:after {
        width: 48px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        background-image: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 21%, rgba(255, 255, 255, 0.96) 68%, rgba(255, 255, 255, 0) 100%);
      }

      &.isScrolling {
        &:before {
          content: '';
          background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 21%, rgba(255, 255, 255, 0.96) 68%, rgba(255, 255, 255, 0) 100%);
          width: 48px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.isScrolledToRight {
        &:after {
          content: none !important;
        }
      }
    }

    &__inner {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .table {
    background: transparent;
    border-collapse: separate;
    font-size: helpers.dynamic-size(14, 16);
    margin-bottom: helpers.dynamic-size(25, 42);
    /*border-collapse: collapse;*/

    tr.active {
      background: var(--brand-primary-100) !important;
    }

    th {
      border: none;
      padding: 0 helpers.dynamic-size(8, 16) helpers.dynamic-size(13, 16);
      line-height: 1.5;
      letter-spacing: -0.16px;
      font-size: helpers.dynamic-size(14, 16);
      font-family: var(--fs-open-sans);
      white-space: nowrap;

      &:last-child {
        text-align: right;
        padding-right: helpers.dynamic-size(60, 70);
      }
    }

    td {
      border: none;
      border-top: 1px solid var(--neutral-200) !important;
      padding: helpers.dynamic-size(8, 13) helpers.dynamic-size(8, 16);
      font-size: 1rem;
      vertical-align: middle;

      &:first-child {
        padding-left: helpers.dynamic-size(8, 16);
        width: 1%;
        white-space: nowrap;
      }

      &:last-child {
        text-align: right;
        padding-right: helpers.dynamic-size(8, 16);
        /*display: flex;
        justify-content: flex-end;
        align-items: center;*/
      }

      &:nth-child(2) {
        font-weight: bold;
      }

      button {
        margin: 0 0 0 16px;
        line-height: 1;
        flex-shrink: 0;
      }

      .dropdown-option--radio {
        display: flex;
        width: 20px;
        height: 20px;
      }

      .dropdown-option--radio input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        cursor: pointer;
        margin: 0;
      }

      .dropdown-option--radio label {
        position: relative;
        //padding-left: 29px;
        line-height: 1.5;
        letter-spacing: -0.16px;
        font-weight: 500;
        font-size: 16px;
        color: var(--neutral-800) !important;
        cursor: pointer;
        min-width: 20px;
      }

      .dropdown-option--radio label:before,
      .dropdown-option--radio label:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        left: 0;
        top: 1px;
      }

      .dropdown-option--radio label:before {
        border: 1px solid var(--white-bg-grey-border);
      }

      .dropdown-option--radio input:checked + label:after {
        border: 6px solid var(--brand-primary-500);
      }
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid var(--neutral-200) !important;
    }
  }

  .actions {
    @media (min-width: 576px) {
      text-align: center;
    }
  }

  .buy-sell-button {
    background-color: #ffc107;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    color: #000;
    display: inline-block;
    margin: 0;
    width: 100%;
    @media (min-width: 576px) {
      width: auto;
    }
  }

  .buy-sell-button:disabled {
    color: var(--neutral-800) !important;
    background-color: var(--brand-secondary-yellow-500) !important;
    border: 1px solid var(--brand-secondary-yellow-600) !important;
    cursor: not-allowed !important;
    opacity: 0.4 !important;
  }

  .agree-checkbox {
    display: flex;
    margin-bottom: 16px;

    @media (min-width: 576px) {
      justify-content: center;
    }


    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;
      margin: 0;

      + label {
        position: relative;
        padding-left: 28px;
        color: var(--neutral-800) !important;
        cursor: pointer;

        &:before {
          content: "";
          border: 1px solid var(--neutral-300);
          position: absolute;
          left: 0;
          top: 4px;
          background-color: #fff;
          width: 18px;
          height: 18px;
          border-radius: 3px;
        }
      }

      &:checked + label:before {
        background-color: var(--brand-primary-500);
        border-color: var(--brand-primary-500);
        background-image: url(../../../../src/images/icon_check-solid_white.svg);
        background-size: 14px auto;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  :global(#top .buy-sell-button:hover:disabled) {
    color: var(--neutral-800) !important;
  }
</style>

<div class="supply-demand">
    <!-- Tabs -->
    <div class="tabs">
        <button
                aria-selected={activeTab === 'supply'}
                class="avia-button avia-color-white avia-size-medium {activeTab === 'supply' ? 'active current-page' : ''}"
                on:click={() => switchTab('supply')}
                on:keydown={(e) => { if (e.key === 'Enter' || e.key === ' ') switchTab('supply'); }}
                role="tab"
                tabindex="0"
                aria-controls="tab-supply"
                id="tab-supply"
        >
            Angebote
        </button>
        <button
                aria-selected="{activeTab === 'demand'}"
                class="avia-button avia-color-white avia-size-medium {activeTab === 'demand' ? 'active current-page' : ''}"
                on:click={() => switchTab('demand')}
                on:keydown={(e) => { if (e.key === 'Enter' || e.key === ' ') switchTab('demand'); }}
                role="tab"
                tabindex="0"
                aria-controls="tab-demand"
                id="tab-demand"
        >
            Nachfrage
        </button>
    </div>

    <!-- Table -->
    <div id="tab-{activeTab}" aria-labelledby="tab-{activeTab}" role="tabpanel">
        <div class="tab-container" class:isOverflowing={isOverflowing} class:isScrolling={isScrolling}
             class:isScrolledToRight={isScrolledToRight}>
            <div class="tab-container__inner" bind:this={tableContainer}>
                <table class="table">
                    <thead>
                    <tr>
                        <th><span class="sr-only">Auswahl</span></th>
                        <th>Stk.</th>
                        <th>Preis/Stk.</th>
                        <th>Gesamtpreis</th>
                        <th>Gültig bis</th>
                        <th>Kontakt</th>
                    </tr>
                    </thead>
                    <tbody aria-live="assertive">
                    {#if loading}
                        {#each {length: 10} as _, i}
                            <tr>
                                <td></td>
                                <td><span class="loading-shimmer">Wird geladen...</span></td>
                                <td><span class="loading-shimmer">Wird geladen...</span></td>
                                <td><span class="loading-shimmer">Wird geladen...</span></td>
                                <td><span class="loading-shimmer">Wird geladen...</span></td>
                                <td><span class="loading-shimmer">Wird geladen...</span></td>
                            </tr>
                        {/each}
                    {:else}
                        {#each tableData as item, index}
                            <tr class:active={(activeTab === "supply" ? supplySelectedItems : demandSelectedItems).has(item.id)}>
                                <td>
                                    <div class="dropdown-option--radio">
                                        <input
                                                id="supply_demand_{slugify(item.id)}"
                                                type="radio"
                                                name="selection"
                                                on:change={() => toggleSelection(item.id)}
                                                checked={(activeTab === "supply" ? supplySelectedItems : demandSelectedItems).has(item.id)}
                                        />
                                        <label for="supply_demand_{slugify(item.id)}"><span
                                                class="sr-only">{item.amount}
                                            Stück zum Preis von {formatCurrency(item.price_single)} pro Stück. Gesamtpreis {formatCurrency(item.price_total)}
                                            . Gültig bis {formatDate(item.valid_until)}</span></label>
                                    </div>
                                </td>
                                <td>{item.amount}</td>
                                <td>{formatCurrency(item.price_single)}</td>
                                <td>{formatCurrency(item.price_total)}</td>
                                <td>{formatDate(item.valid_until)}</td>
                                <td>
                                    {item.contact_name}
                                    <button class="avia-button avia-color-theme-color avia-size-xs"
                                            aria-label="Kontaktieren"
                                            on:click={() => window.open(item.link, '_blank')}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </button>
                                </td>
                            </tr>
                        {/each}
                    {/if}
                    </tbody>
                </table>
            </div>
        </div>

        {#if !loading}
            <div class="actions">
                <div class="agree-checkbox">
                    <input
                            id="agree-checkbox"
                            type="checkbox"
                            checked={termsAccepted[activeTab]}
                            on:change={(e) => {
                                termsAccepted = Object.assign({}, termsAccepted, { [activeTab]: e.target.checked });
                            }}
                    />
                    <label for="agree-checkbox">Ja, lorem ipsum annehmen</label>
                </div>
                <button
                        class="buy-sell-button avia-button avia-color-theme-color avia-size-medium"
                        on:click={handleBuySellClick}
                        disabled={!(termsAccepted[activeTab] && (activeTab === "supply" ? supplySelectedItems.size > 0 : demandSelectedItems.size > 0))}
                >
                    {activeTab === "supply" ? "Verbindlich kaufen" : "Verkaufen"}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                </button>
            </div>
        {/if}
    </div>
</div>
