<script>
    import Select from 'svelte-select';
    import {v4 as uuidv4} from "uuid";
    import {FontAwesomeIcon} from "@fortawesome/svelte-fontawesome";
    import {faChevronDown} from "@fortawesome/pro-solid-svg-icons";
    import {createEventDispatcher, onMount} from 'svelte';
    import {writable} from "svelte/store";

    export let label;
    export let hideLabel = true;
    export let value;
    export let name;
    export let options;
    export let iconBefore = '';
    export let searchable = false;
    export let clearable = false;
    export let placeholder = "Bitte wählen";
    export let required = false;
    export let form = writable({});

    const uniqueId = uuidv4().replace(/-/g, "");

    const dispatch = createEventDispatcher();

    // Forward the event to the parent component
    function handleChange(event) {
        if (event.detail && event.detail.value !== undefined) {
            //console.log('Dispatching to parent component:', event.detail.value);
            dispatch('change', event.detail.value);
        } else {
            //console.error('No value received from the select component');
        }
    }

    let element;
    let isListOpen = false;

    $: if (value) {
        if ($form[name]) {
            $form[name].valid = true;
        }
    } else {
        if ($form[name]) {
            if (required) {
                $form[name].valid = false;
            }
        }
    }

    $: if (isListOpen) {
        setTimeout(function () {
            const svelteSelectList = document.querySelector('.svelte-select.list-open .svelte-select-list');
            if (svelteSelectList) {
                svelteSelectList.setAttribute('role', 'listbox');
                svelteSelectList.setAttribute('id', 'controls_' + uniqueId);
                const listItems = svelteSelectList.querySelectorAll('.list-item');
                listItems.forEach((item, key) => {
                    item.setAttribute('role', 'option');
                    item.setAttribute('id', options[key].value);
                });
            }
        }, 0);
    }

    let ariaValues = (values) => {
        return `Option ${values}, ausgewählt.`;
    }

    let ariaListOpen = (label, count) => {
        return `Sie haben aktuell die Option ${label} fokusiert. Es gibt insgesamt ${count} Auswahlmöglichkeiten.`;
    }

    let ariaFocused = () => {
        return `Select is focused, type to refine list, press down to open the menu.`;
    }

    // Zum debuggen des dropdowns
    /*onMount(() => {
        setInterval(() => {
            isListOpen = true
        }, 15000);
    });*/

    /*
    // Reactively log what we receive from the parent component
    $: {
        console.log('Received from parent component: ', value);
        console.log('Options received from parent component: ', options);
    }
    */

</script>
<style lang="scss">
  :global(#top .oekp_select label) {
    font-size: .75rem;
    font-weight: 700;
    display: block;
    letter-spacing: -.12px;
    color: var(--neutral-500);
    line-height: 1.5;
    margin-bottom: 8px;
  }

  .oekp_select {
    z-index: 9;

    :global(.svelte-select input::placeholder) {
      color: var(--neutral-800) !important;
      opacity: 1;
    }

    :global(.svelte-select input:-ms-input-placeholder) {
      color: var(--neutral-800) !important;
    }

    :global(.svelte-select input::-ms-input-placeholder) {
      color: var(--neutral-800) !important;
    }

    :global( + .oekp_select) {
      margin-top: 24px;
    }

    :global(.svelte-select) {
      padding-left: 9px !important;
    }

    :global(.prepend .svg-inline--fa) {
      color: var(--neutral-500);
      font-size: 14px;
      line-height: 1.429;
    }

    :global(.value-container) {
      padding: 0 !important;
      height: auto;
    }

    :global(input) {
      padding: 10px !important;
      background-color: transparent !important;
    }

    :global(.selected-item) {
      padding: 9px 0 9px 0 !important;
      line-height: 1.429;
    }

    :global(.svelte-select),
    :global(.prepend),
    :global(.value-container),
    :global(input[type="text"]),
    :global(.selected-item),
    :global(.indicators),
    :global(.indicators .chevron) {
      height: 40px !important;
    }

    :global(.svelte-select.focused .svelte-select),
    :global(.svelte-select.focused .prepend),
    :global(.svelte-select.focused .value-container),
    :global(.svelte-select.focused input[type="text"]),
    :global(.svelte-select.focused .selected-item),
    :global(.svelte-select.focused .indicators),
    :global(.svelte-select.focused .indicators .chevron) {
      height: 38px !important;
    }
  }

  :global(#top #wrap_all .oekp_select .svelte-select.focused input[type="text"]) {
    background: transparent !important;
  }

  :global(#top #wrap_all .oekp_select .svelte-select.focused .selected-item) {
    padding: 8px 0 8px 0 !important;
  }

  :global(#top .oekp_select .svelte-select) {
    height: 40px !important;
  }

  :global(html #top #wrap_all .svelte-select input, html #top #wrap_all .svelte-select input:focus) {
    border: none !important;
    box-shadow: none !important;
  }

  :global(#top .svelte-select) {
    box-shadow: 0 2px 4px -1px rgba(5, 6, 5, .1);
  }

  :global(.svelte-select:hover) {
    border: 1px solid rgba(220, 221, 221, 1) !important;
  }

  :global(#top .svelte-select-list) {
    z-index: 99;
    padding: 16px 5px;
    max-width: 100%;
    /* Hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  :global(#top .svelte-select-list .item) {
    border-radius: 6px;
    padding-left: 11px;
    padding-right: 11px;
  }

  :global(#top .svelte-select-list .item.first.hover),
  :global(.item.hover:not(.active)) {
    background-color: #fff !important;
    cursor: pointer !important;
    box-shadow: inset 0 0 0 2px #05A56F;
  }

  :global(#top .svelte-select-list .item:hover),
  :global(#top .svelte-select-list .item.first:hover) {
    background-color: var(--neutral-100) !important;
    cursor: pointer !important;
    box-shadow: none !important;
  }

  :global(#top .svelte-select-list .item.active) {
    background-color: var(--neutral-100) !important;
    color: var(--neutral-800) !important;
  }

  :global(.selected-item) {
    z-index: 9;
  }

  :global(.svelte-select.focused) {
    border: 2px solid var(--brand-primary-500) !important;
    box-shadow: 0 0 0 3px rgba(5, 165, 111, .25), inset 0 2px 4px 0 rgba(5, 6, 5, .1) !important;
  }

</style>

<div class="oekp_select">
    <label for="select_{uniqueId}" class:sr-only={hideLabel}>{label}</label>
    <Select
            bind:this={element}
            on:input={handleChange}
            id="select_{uniqueId}"
            inputAttributes="{{'role':'combobox', 'aria-expanded': isListOpen, 'aria-controls': 'controls_' + uniqueId, 'data-active-option': value ? value.value : ''}}"
            items={options}
            bind:value
            name="{name}"
            showChevron="{true}"
            {searchable}
            required="{required}"
            bind:listOpen="{isListOpen}"
            {clearable} placeholder={placeholder} {ariaValues} {ariaListOpen} {ariaFocused}>
        <svelte:fragment slot="prepend">
            {#if iconBefore}
                <FontAwesomeIcon icon="{iconBefore}"/>
            {/if}
        </svelte:fragment>
        <svelte:fragment slot="chevron-icon">
            <FontAwesomeIcon icon="{faChevronDown}"/>
        </svelte:fragment>
    </Select>
</div>