<script>
    import {onMount} from "svelte";
    import Chart from "../elements/Chart.svelte";
    import 'chartjs-adapter-date-fns';
    import FilterOptions from "../elements/FilterOptions.svelte";
    import {
        fetchApiYearQuarterInfo,
        fetchData,
        getQuarterYearOptions,
        getLastNQuarters,
        getChartOptions,
        getChartDataset,
        getMatchingTimeframe,
        filterEndQuarterOptions,
        getDropdownValuesForTimeframe,
        getFilterLabel,
        OEKOSTROM_COLORS
    } from "../js/sharedFunctions.js";
    import SelectField from "../elements/SelectField.svelte";
    import DropDownOption from "../elements/DropDownOption.svelte";
    import PriceBox from "../elements/PriceBox.svelte";
    import {faCalendarClock} from "@fortawesome/pro-solid-svg-icons";
    import PlaceholderChart from "../elements/PlaceholderChart.svelte";

    // Chart data and styling
    let chartData = null;
    const defaultColor = OEKOSTROM_COLORS.blue;

    // Loading state
    let isLoading = true;

    // Volume display values
    let currentVolume = null;
    let outputCurrentVolume = "N/A";
    let volumeChange = null;
    let outputVolumeChange = "N/A";

    // API constraints
    let apiEarliestYear = null;
    let apiEarliestQuarter = null;
    let apiLatestYear = null;
    let apiLatestQuarter = null;

    // Selected timeframe (default to five years)
    let selectedTimeframe = "five-years";

    // Quarter options
    let allQuarterOptions = [];
    let startQuarterOptions = [];
    let endQuarterOptions = [];

    // Selected quarters
    let selectedStartOption = "";
    let selectedEndOption = "";

    // Has the user made an explicit selection?
    let userExplicitSelection = false;

    // For the new SelectField component
    let selectedStartOptionObj = null;
    let selectedEndOptionObj = null;

    // We need to know which options are available for the user to select
    let oneYearAvailable = false;
    let threeYearsAvailable = false;
    let fiveYearsAvailable = false;
    let tenYearsAvailable = false;

    // Timeframe quarters mapping
    const timeframeQuarters = {
        "one-year": 4,
        "three-years": 12,
        "five-years": 20,
        "ten-years": 40
    };

    // We need to know if we have the necessary data for each timeframe.
    function updateAvailableTimeframes() {
        if (!apiEarliestYear || !apiLatestYear || !apiEarliestQuarter || !apiLatestQuarter) {
            return; // API data not availavle
        }

        // Calculate total quarters in the dataset
        const totalYears = apiLatestYear - apiEarliestYear;
        const totalQuarters = (totalYears * 4) + (apiLatestQuarter - apiEarliestQuarter + 1);

        // Determine which timeframes are available based on required quarters
        oneYearAvailable = totalQuarters > timeframeQuarters["one-year"];
        threeYearsAvailable = totalQuarters > timeframeQuarters["three-years"];
        fiveYearsAvailable = totalQuarters > timeframeQuarters["five-years"];
        tenYearsAvailable = totalQuarters > timeframeQuarters["ten-years"];

        // If the exact number of quarters matches a timeframe, that timeframe is redundant with "all-time".
        const oneYearRedundant = totalQuarters === timeframeQuarters["one-year"];
        const threeYearsRedundant = totalQuarters === timeframeQuarters["three-years"];
        const fiveYearsRedundant = totalQuarters === timeframeQuarters["five-years"];
        const tenYearsRedundant = totalQuarters === timeframeQuarters["ten-years"];

        // Update available flags to also consider redundancy
        oneYearAvailable = oneYearAvailable && !oneYearRedundant;
        threeYearsAvailable = threeYearsAvailable && !threeYearsRedundant;
        fiveYearsAvailable = fiveYearsAvailable && !fiveYearsRedundant;
        tenYearsAvailable = tenYearsAvailable && !tenYearsRedundant;

        // If the selected timeframe is no longer available, default to "all-time"
        if ((selectedTimeframe === "one-year" && !oneYearAvailable) ||
            (selectedTimeframe === "three-years" && !threeYearsAvailable) ||
            (selectedTimeframe === "five-years" && !fiveYearsAvailable) ||
            (selectedTimeframe === "ten-years" && !tenYearsAvailable)) {
            selectedTimeframe = "all-time";
            updateDropdownsForSelectedTimeframe();
        }
    }

    // Initialize on component mount
    onMount(async () => {
        try {
            // Fetch API year/quarter info
            const apiInfo = await fetchApiYearQuarterInfo();
            if (apiInfo) {
                apiEarliestYear = apiInfo.apiEarliestYear;
                apiLatestYear = apiInfo.apiLatestYear;
                apiEarliestQuarter = apiInfo.apiEarliestQuarter;
                apiLatestQuarter = apiInfo.apiLatestQuarter;

                // Generate all possible quarter options
                allQuarterOptions = getQuarterYearOptions(
                    apiEarliestYear,
                    apiEarliestQuarter,
                    apiLatestYear,
                    apiLatestQuarter,
                    apiEarliestYear,
                    apiEarliestQuarter,
                    apiLatestYear,
                    apiLatestQuarter
                );

                // Set initial dropdown values based on selected timeframe
                updateAvailableTimeframes();
                updateDropdownsForSelectedTimeframe();

                // Update start and end options
                startQuarterOptions = [...allQuarterOptions];
                updateEndOptions();

                // Find and set the corresponding option objects
                selectedStartOptionObj = startQuarterOptions.find(opt => opt.value === selectedStartOption);
                selectedEndOptionObj = endQuarterOptions.find(opt => opt.value === selectedEndOption);

                // Fetch initial data
                await fetchTradingVolume();
            }
        } catch (error) {
            //console.error("Error during initialization:", error);
        } finally {
            isLoading = false;
        }
    });

    // Function to update dropdowns based on selected timeframe
    function updateDropdownsForSelectedTimeframe() {
        if (!apiLatestYear || !apiLatestQuarter) return;

        // Use the shared function to get dropdown values
        const dropdownValues = getDropdownValuesForTimeframe(
            selectedTimeframe,
            timeframeQuarters,
            apiEarliestYear,
            apiEarliestQuarter,
            apiLatestYear,
            apiLatestQuarter,
            selectedStartOption,
            selectedEndOption
        );

        selectedStartOption = dropdownValues.startOption;
        selectedEndOption = dropdownValues.endOption;

        // Update start and end dropdown options
        startQuarterOptions = [...allQuarterOptions];
        updateEndOptions();

        // Find and set the corresponding option objects
        selectedStartOptionObj = startQuarterOptions.find(opt => opt.value === selectedStartOption);
        selectedEndOptionObj = endQuarterOptions.find(opt => opt.value === selectedEndOption);
    }

    // Update end options whenever start option changes
    function updateEndOptions() {
        // Use the shared function to filter end options
        endQuarterOptions = filterEndQuarterOptions(allQuarterOptions, selectedStartOption);

        // Ensure end selection is valid
        if (selectedEndOption) {
            const [startQuarter, startYear] = selectedStartOption.split("/").map(Number);
            const [endQuarter, endYear] = selectedEndOption.split("/").map(Number);

            if (endYear < startYear || (endYear === startYear && endQuarter < startQuarter)) {
                // Reset to first valid option if current end is invalid
                selectedEndOption = endQuarterOptions[0]?.value || selectedStartOption;
            }
        }
    }

    // Handler for timeframe radio button selection
    function handleTimeframeChange(value) {
        userExplicitSelection = true;
        selectedTimeframe = value;

        // If user selects "custom", don't change the dropdown values
        if (value === "custom") {
            // If no selection exists, set default values
            if (!selectedStartOption || !selectedEndOption) {
                // Default to 5 years if no selection
                const options = getLastNQuarters(20, apiLatestYear, apiLatestQuarter);
                if (options.length > 0) {
                    selectedStartOption = options[0].value;
                    selectedEndOption = options[options.length - 1].value;

                    // Update end options
                    updateEndOptions();
                }
            }
            return;
        }

        updateDropdownsForSelectedTimeframe();
    }

    // Handle dropdown changes
    function handleStartChange(event) {
        selectedStartOption = selectedStartOptionObj ? selectedStartOptionObj.value : "";
        updateEndOptions();
        selectedEndOptionObj = endQuarterOptions.find(opt => opt.value === selectedEndOption);
        userExplicitSelection = false;
    }

    function handleEndChange(event) {
        selectedEndOption = selectedEndOptionObj ? selectedEndOptionObj.value : "";
        userExplicitSelection = false;
    }

    // Trigger timeframe check when dropdown values change
    $: if (selectedStartOption && selectedEndOption && !userExplicitSelection) {
        // Use the shared function to check for timeframe match
        const matchedTimeframe = getMatchingTimeframe(
            selectedStartOption,
            selectedEndOption,
            apiEarliestYear,
            apiEarliestQuarter,
            apiLatestYear,
            apiLatestQuarter,
            timeframeQuarters
        );

        if (matchedTimeframe && selectedTimeframe !== "custom") {
            selectedTimeframe = matchedTimeframe;
        }
    }

    // Compute filter label using shared function
    $: filterLabel = getFilterLabel(selectedTimeframe, selectedStartOption, selectedEndOption);

    // Keep string values updated when objects change
    $: if (selectedStartOptionObj) {
        selectedStartOption = selectedStartOptionObj.value;
    }
    $: if (selectedEndOptionObj) {
        selectedEndOption = selectedEndOptionObj.value;
    }

    // Fetch data for the selected range
    const fetchTradingVolume = async () => {
        if (!selectedStartOption || !selectedEndOption) return;

        // Set loading state
        isLoading = true;

        try {
            const [startQuarter, startYear] = selectedStartOption.split("/").map(Number);
            const [endQuarter, endYear] = selectedEndOption.split("/").map(Number);

            // Fetch data
            const rawData = await fetchData(
                startYear,
                apiLatestYear,
                startQuarter,
                apiLatestQuarter,
                "tradingVolume"
            );

            chartData = rawData.chartData;
            currentVolume = rawData.currentVolume;
            volumeChange = rawData.volumeChange;

            if (chartData?.labels?.length) {
                // Create structured data points
                const allDataPoints = chartData.labels
                    .map((label, index) => ({
                        label,
                        index,
                        year: parseInt(label.split("/")[1], 10),
                        quarter: parseInt(label.split("/")[0].replace("Q", ""), 10),
                        value: chartData.datasets[0]?.data[index] ?? 0
                    }))
                    .sort((a, b) => (a.year === b.year ? a.quarter - b.quarter : a.year - b.year));

                // Filter data to selected range
                const filteredDataPoints = allDataPoints.filter(d =>
                    (d.year > startYear || (d.year === startYear && d.quarter >= startQuarter)) &&
                    (d.year < endYear || (d.year === endYear && d.quarter <= endQuarter))
                );

                // Update chart with filtered data
                if (filteredDataPoints.length > 0) {
                    chartData.labels = filteredDataPoints.map(d => d.label);
                    chartData.datasets = [
                        getChartDataset(
                            filteredDataPoints.map(d => d.value),
                            "bar", // Bar chart for trading volume
                            "Handelsvolumen in Tsd.",
                            defaultColor,
                            "#ffffff",
                            OEKOSTROM_COLORS.grey,
                            '0.2',
                            "currencyLarge"
                        )
                    ];
                }
            }

            // Format volume for display
            outputCurrentVolume = currentVolume
                ? "€ " + currentVolume.toLocaleString('de-AT', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
                : "N/A";

            outputVolumeChange = volumeChange !== null
                ? volumeChange.toLocaleString('de-AT', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
                : "N/A";
        } catch (error) {
            //console.error("Error fetching trading volume data:", error);
        } finally {
            isLoading = false;
        }
    };

    // Apply filter button handler
    const applyFilter = () => {
        fetchTradingVolume();
    };
</script>

<style lang="scss">
    .filter-section {
        display: flex;
        align-items: center;

        :global(.oekp_select) {
            flex: 1;
        }

        > span {
            flex-shrink: 0;
            margin: 0 helpers.dynamic-size(8, 15);
        }
    }

    .fieldset {
        > span {
            font-size: 0.75rem;
            font-weight: bold;
            display: block;
            letter-spacing: -0.12px;
            color: var(--neutral-500);
            line-height: 1.5;
            margin-bottom: 8px;
        }

        + .fieldset {
            border-top: 1px solid var(--neutral-200);
            margin-top: 16px;
            padding-top: 12px;
        }
    }
</style>

<div class="trading-volume">
        <!-- Current Volume Display Box -->
        <PriceBox title="Handelsvolumen · Q{apiLatestQuarter}/{apiLatestYear}" price={outputCurrentVolume}
                  priceChange={volumeChange} priceChangeOutput="{outputVolumeChange} % zu {apiLatestYear - 1}" isLoading={isLoading}/>

        <FilterOptions label="Anzeige & Zeitraum" selectedValue={filterLabel} on:apply={applyFilter}
                       icon="{faCalendarClock}">
            <div class="fieldset">
                <span>Zeitraum</span>
                {#if oneYearAvailable}
                    <DropDownOption
                            value="one-year"
                            name="tradingVolume"
                            group={selectedTimeframe}
                            label="Letztes Jahr"
                            onChange={handleTimeframeChange}
                    />
                {/if}

                {#if threeYearsAvailable}
                    <DropDownOption
                            value="three-years"
                            name="tradingVolume"
                            group={selectedTimeframe}
                            label="Letzte 3 Jahre"
                            onChange={handleTimeframeChange}
                    />
                {/if}

                {#if fiveYearsAvailable}
                    <DropDownOption
                            value="five-years"
                            name="tradingVolume"
                            group={selectedTimeframe}
                            label="Letzte 5 Jahre"
                            onChange={handleTimeframeChange}
                    />
                {/if}

                {#if tenYearsAvailable}
                    <DropDownOption
                            value="ten-years"
                            name="tradingVolume"
                            group={selectedTimeframe}
                            label="Letzte 10 Jahre"
                            onChange={handleTimeframeChange}
                    />
                {/if}

                <DropDownOption
                        value="all-time"
                        name="tradingVolume"
                        group={selectedTimeframe}
                        label="Gesamtzeitraum"
                        onChange={handleTimeframeChange}
                />

                <DropDownOption
                        value="custom"
                        name="tradingVolume"
                        group={selectedTimeframe}
                        label="Benutzerdefiniert"
                        onChange={handleTimeframeChange}
                />
            </div>

            <div class="fieldset">
                <span>Zeitraum nach Quartal</span>
                <!-- Quarter-Year Filter -->
                <div class="filter-section">
                    <SelectField
                            label="Von"
                            name="quarterly-select-from"
                            options={startQuarterOptions}
                            bind:value={selectedStartOptionObj}
                            on:change={handleStartChange}
                    />
                    <span>bis</span>
                    <SelectField
                            label="Bis"
                            name="quarterly-select-to"
                            options={endQuarterOptions}
                            bind:value={selectedEndOptionObj}
                            on:change={handleEndChange}
                    />
                </div>
            </div>
        </FilterOptions>

        <!-- Chart Section -->
        {#if chartData && !isLoading}
            <Chart
                    type="bar"
                    data={{
                    labels: chartData.labels,
                    datasets: [
                        {
                            ...getChartDataset(
                                chartData.datasets[0].data,
                                "bar",
                                "Handelsvolumen in EUR",
                                OEKOSTROM_COLORS.blue,
                                "#fff",
                                OEKOSTROM_COLORS.blue,
                                "0.5",
                                "currencyLarge"
                            ),
                            yAxisID: "y",
                            datatype: "currencyLarge"
                        }

                    ]
                }}
                    options={getChartOptions(
                    chartData,
                    "Quarter",
                    "Handelsvolumen in EUR",
                    true,
                    false,
                    true,
                    "quarter",
                    OEKOSTROM_COLORS.blue,
                    "bar"
                )}
            />
        {:else if isLoading}
            <PlaceholderChart type="bar"/>
        {/if}
</div>