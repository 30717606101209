<script>
    import {onDestroy, onMount} from "svelte";
    import Chart from "chart.js/auto";
    import {Tooltip} from "chart.js";
    import {customMarkerPlugin, customTickCircles} from "../js/chartJsPlugins.js";

    Chart.register(customTickCircles, customMarkerPlugin, Tooltip);

    export let type = "bar";
    export let data;
    export let options = {};
    export let canvasMinHeight = 450;

    let canvas;
    let chartInstance = null;

    // Create chart instance
    const createChart = () => {
        try {
            if (chartInstance !== null) {
                chartInstance.destroy();
                chartInstance = null;
            }

            if (canvas) {
                requestAnimationFrame(() => {
                    if (!canvas) return; // Ensure canvas is still available before initializing
                    chartInstance = new Chart(canvas, {
                        type,
                        data,
                        options,
                    });
                    //console.log("Chart created successfully");
                });
            }
        } catch (error) {
            //console.error("Failed to create chart:", error);
        }
    };

    // Create chart when mounted
    onMount(() => {
        //console.log("Chart.svelte mounted");
        requestAnimationFrame(() => {
            if (canvas) {
                createChart();
            }
        });
    });

    // Destroy chart when component is unmounted
    onDestroy(() => {
        if (chartInstance !== null) {
            chartInstance.destroy();
            chartInstance = null;
        }
    });

    $: if (chartInstance && data) {
        //console.log("Updating chart data");
        chartInstance.data = data;
        chartInstance.options = options;
        chartInstance.update();
    }
</script>

<style>
    .canvas-container {
        position: relative;
        min-height: 450px;
        height: 100%;
        width: 100%;
    }

    canvas {
        position: relative;
    }
</style>

<div class="canvas-container {$$props.class} type--{type}">
    <canvas bind:this={canvas}></canvas>
</div>
