import { mount } from "svelte";

// Map component names to actual imports
const componentMap = {
    Dividends: () => import("../components/Dividends.svelte"),
    SharePrices: () => import("../components/SharePrices.svelte"),
    ShareDistribution: () =>(import("../components/ShareDistribution.svelte")),
    SupplyDemand: () => import("../components/SupplyDemand.svelte"),
    TradingVolume: () => import("../components/TradingVolume.svelte"),
};

document.addEventListener("DOMContentLoaded", async () => {
    //console.log("Checking for Svelte components...");

    const mountPoints = document.querySelectorAll(".svelte-mount");

    if (mountPoints.length === 0) {
        //console.warn("No Svelte mount points found.");
        return;
    }

    //console.log(`Found ${mountPoints.length} Svelte mount points.`);

    for (const [index, el] of mountPoints.entries()) {
        const componentName = el.dataset.svelteComponent;

        if (!componentName) {
            //console.error("Missing 'data-svelte-component' on element:", el);
            continue;
        }

        if (!(componentName in componentMap)) {
            //console.error(`No matching Svelte component found for '${componentName}'`);
            continue;
        }

        try {
            console.log(`Loading Svelte component: ${componentName}`);

            // Dynamically import the component
            const { default: Component } = await componentMap[componentName]();

            // Extract all data-* attributes as props
            const props = {};
            for (const attr of el.attributes) {
                if (attr.name.startsWith("data-") && attr.name !== "data-svelte-component") {
                    let propName = attr.name.slice(5).replace(/-./g, (x) => x[1].toUpperCase());
                    let value = attr.value;

                    // Auto-detect numbers and booleans
                    if (!isNaN(value) && value.trim() !== "") {
                        value = Number(value);
                    } else if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
                        value = value.toLowerCase() === "true";
                    }

                    props[propName] = value;
                }
            }

            // Assign a unique ID to prevent data conflicts
            props.instanceId = `svelte-${componentName}-${index}`;

            console.log(`Mounting '${componentName}' with props:`, props);

            // Mount the component with extracted props
            mount(Component, {
                target: el,
                props
            });

            console.log(`Successfully mounted '${componentName}' on:`, el);
        } catch (error) {
            console.error(`Failed to load '${componentName}':`, error);
        }
    }
});
