<script>
    import {onMount} from "svelte";
    import Chart from "../elements/Chart.svelte";
    import {getHistoricData, getShareDistribution} from "../js/apiHandler.js";
    import {getChartOptions, OEKOSTROM_COLORS} from "../js/sharedFunctions.js";
    import FilterOptions from "../elements/FilterOptions.svelte";
    import DropDownOption from "../elements/DropDownOption.svelte";
    import {FontAwesomeIcon} from "@fortawesome/svelte-fontawesome";
    import {faChevronDown} from "@fortawesome/pro-solid-svg-icons";
    import PlaceholderChart from "../elements/PlaceholderChart.svelte";
    import oeLogo from '../../images/oe-logo.svg'

    let chartData = null;
    let errorMessage = null;
    let isLoading = false;
    let isLoadingTransactions = false;
    let showFurtherInformation = false;
    let filterIsOpen = false;

    let totalShares = 0;
    let totalShareholders = 0;
    let shareCapital = 0;

    let totalSharesBoard = 0;
    let totalSharesEmployees = 0;

    let selectedGroup = "general";
    let confirmedGroup = "general";

    let hasTransactionData = false;
    let showTransactionData = false;
    let transactionsDataBoard = null;
    let transactionsDataEmployees = null;

    // Compute filter label dynamically
    $: filterLabel =
        selectedGroup === "general" ? "Allgemein" :
            selectedGroup === "age-groups" ? "Nach Alter" :
                selectedGroup === "gender" ? "Nach Geschlecht" : "Organe und Mitarbeiter:innen";

    const filterOptions = [
        {value: "general", label: "Allgemein"},
        {value: "age-groups", label: "Nach Alter"},
        {value: "gender", label: "Nach Geschlecht"},
        {value: "mix", label: "Organe und Mitarbeiter:innen"},
    ];

    // Fetch transaction data
    const fetchTransactionData = async () => {
        isLoadingTransactions = true;

        try {
            const historicDataResponse = await getHistoricData();
            const historicData = historicDataResponse?.response || null;
            const transactionsData = historicData?.transactions || null;

            // Save the transaction data to our component variables
            transactionsDataBoard = transactionsData?.board || null;
            transactionsDataEmployees = transactionsData?.employees || null;

            // Set flag based on whether we have any transaction data
            hasTransactionData = Boolean(transactionsDataBoard || transactionsDataEmployees);

            //console.log('Board Transactions:', transactionsDataBoard);
            //console.log('Employees Transactions:', transactionsDataEmployees);
        } catch (error) {
            //console.error("Error fetching transaction data:", error);
            errorMessage = "Fehler beim Abrufen der Transaktionsdaten.";
        } finally {
            isLoadingTransactions = false;
        }
    };

    // Fetch data on mount or group change
    const fetchShareData = async () => {
        errorMessage = null;
        isLoading = true;

        try {
            let data = null;

            if (selectedGroup === "mix") {
                const boardResponse = await getShareDistribution("board");
                const employeesResponse = await getShareDistribution("employees");

                // Ensure valid data...
                const boardData = boardResponse?.response?.group_data || {split: [], total_shares_group: 0};
                const employeesData = employeesResponse?.response?.group_data || {split: [], total_shares_group: 0};

                // Ensure `split` is always an array!
                const splitBoard = Array.isArray(boardData.split) ? boardData.split : [];
                let splitEmployees = Array.isArray(employeesData.split) ? employeesData.split : [];

                // Store total shares separately for Board & Employees
                totalSharesBoard = (boardData.total_shares_group || 0).toLocaleString();
                totalSharesEmployees = (employeesData.total_shares_group || 0).toLocaleString();

                // Merge data for chart...
                const mergedSplitData = [...splitBoard, ...splitEmployees];
                data = {
                    response: {
                        group_data: {
                            split: mergedSplitData,
                            total_shares_group: (boardData.total_shares_group || 0) + (employeesData.total_shares_group || 0),
                        },
                    },
                };
            } else {
                data = await getShareDistribution(selectedGroup);
            }

            // Validate response before proceeding
            if (!data?.response?.group_data) {
                errorMessage = "Daten sind für diese Gruppe nicht verfügbar.";
                chartData = null;
                return;
            }

            // Extract and validate data
            const generalData = data.response;
            const groupData = data.response?.group_data;
            const splitData = Array.isArray(groupData?.split) ? groupData.split : [];

            if (splitData.length === 0 || splitData.every(item => typeof item.shares === "undefined")) {
                errorMessage = "Daten sind für diese Gruppe nicht verfügbar.";
                chartData = null;
                return;
            }

            // todo: add "Weitere Infos" element below the graph for board/employees (mix)

            // Prepare chart data
            const labels = splitData.map(item => item.label);
            const values = splitData.map(item => Number(item.shares) || 0);
            const colors = splitData.map(
                (_, index) =>
                    [
                        OEKOSTROM_COLORS.green,
                        OEKOSTROM_COLORS.blue,
                        OEKOSTROM_COLORS.violet,
                        OEKOSTROM_COLORS.yellow,
                        OEKOSTROM_COLORS.darkgreen,
                        OEKOSTROM_COLORS.orange,
                        OEKOSTROM_COLORS.grey
                    ][index % 6]
            );

            // Prepare dynamic legend details based on selected group
            const legendDetails = splitData.map(item => {
                const shareHolders = item.share_holders ?? 0;
                const label = item.label ?? "Unbekannt";

                //console.log('Selected Group:', selectedGroup);

                if (selectedGroup === "general") {
                    const shareHoldersText = shareHolders === 1 ? "Aktionär:in" : "Aktionär:innen";
                    return `${shareHolders} ${shareHoldersText} mit ${label} Stk.`;
                } else if (selectedGroup === "mix") {
                    if (label.startsWith("Dr.") || label.includes("Vorstand")) {
                        return `${label}`;
                    } else {
                        return `${shareHolders} Mitarbeiter:innen`;
                    }
                } else if (selectedGroup === "age-groups") {
                    return `${shareHolders} Personen im Alter ${label}`;
                } else if (selectedGroup === "gender") {
                    const genderLabel = label === "Weiblich" ? "weibliche" :
                        label === "Männlich" ? "männliche" :
                            label === "Divers" ? "diverse" :
                                "Firmen";
                    if (genderLabel === "Firmen") {
                        return `${shareHolders} ${genderLabel}`;
                    } else {
                        return `${shareHolders} ${genderLabel} Aktionär:innen`;
                    }
                } else {
                    const shareHoldersText = shareHolders === 1 ? "Aktionär:in" : "Aktionär:innen";
                    return `${shareHolders} ${shareHoldersText}`;
                }
            });

            chartData = {
                labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: colors,
                    },
                ],
                legendDetails
            };

            // Update displayed values
            if (selectedGroup === "mix") {
                totalShares = (parseInt(totalSharesBoard.replace(/\./g, "")) + parseInt(totalSharesEmployees.replace(/\./g, ""))).toLocaleString();
                totalShareholders = "N/A"; // Since mix doesn't have a single total shareholder count
                shareCapital = "N/A"; // We don't need capital for mix
            } else {
                totalShares = generalData.group_data.total_shares_group.toLocaleString();
                totalShareholders = generalData.total_share_holders.toLocaleString();
                shareCapital = generalData.share_capital.toLocaleString('de-AT', {style: 'currency', currency: 'EUR'});
            }

            //console.log("Total Shares (Mix):", totalShares);
            //console.log("Board Shares:", totalSharesBoard);
            //console.log("Employees Shares:", totalSharesEmployees);

        } catch (error) {
            //console.error("Error fetching share distribution:", error);
            errorMessage = "Fehler beim Abrufen der Daten.";
        } finally {
            isLoading = false;
        }
    };

    function toggleTransactionHistoryDisplay() {
        showTransactionData = !showTransactionData;
    }

    function handleApplyFilter() {
        showFurtherInformation = false;
        fetchShareData();
        confirmedGroup = selectedGroup;

        if(selectedGroup === "mix") {
            showFurtherInformation = true;
        }
    }

    onMount(async () => {
        await fetchShareData();
        await fetchTransactionData();
        if(selectedGroup === "mix") {
            showFurtherInformation = true;
        }
    });

    $: isAnyLoading = isLoading || isLoadingTransactions;
</script>

<style lang="scss">
  .share-distribution {
    :global(.loading-chart) {
      margin-top: 24px;
    }
  }

  .row {
    --bs-gutter-x: 8px;
    --bs-gutter-y: 8px;
    margin-bottom: 8px;
  }

  .share-distribution__cards .row:last-child {
    margin-bottom: 0;
  }

  :global(.share-distribution .loading-chart img) {
    max-width: helpers.dynamic-size(235, 350);
  }

  :global(.donut_chart_container) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  :global(.donut_chart_container .type--doughnut) {
    height: auto;
    width: 100%;
    max-width: helpers.dynamic-size(200, 312);
    min-height: auto;
  }

  :global(.donut_chart_container .type--doughnut canvas) {
    width: 100% !important;
    height: auto !important;
  }

  .share-distribution__card {
    border: 1px solid var(--neutral-200);
    box-shadow: 0 2px 4px -1px rgba(5, 6, 5, .1);
    border-radius: 8px;
    padding: helpers.dynamic-size(12, 16);
    font-weight: bold;
    font-size: helpers.dynamic-size(30, 32);
    line-height: 1;
    height: 100%;

    > div {
      display: flex;
    }
  }

  .share-distribution__card__label {
    letter-spacing: -0.16px;
    line-height: 1.5;
    font-size: helpers.dynamic-size(14, 16);
    color: var(--neutral-500);
    margin-bottom: 8px;
  }

  .share-distribution__card__value {
    font-family: var(--fs-tt-firs-neue);
  }

  /* For custom legend */
  .custom-legend {
    display: flex;
    flex-direction: column;
    margin-top: helpers.dynamic-size(24, 33);
  }

  .legend-item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: helpers.dynamic-size(16, 24);
    }
  }

  .legend-color {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 12px;
    flex-shrink: 0;
  }

  .legend-value {
    font-weight: bold;
    margin-right: 15px;
    font-size: 18px;
    font-family: var(--fs-tt-firs-neue);
    flex-shrink: 0;
    line-height: 1;
  }

  .legend-detail {
    font-size: 14px;
    margin-left: auto;
    text-align: right;
    line-height: 1.5;
    letter-spacing: -0.01em;
  }

  /* Further information box */
  .further-info {
    margin-top: 24px;

    button {
      display: flex;
      align-items: center;
      line-height: 1.5;
      letter-spacing: -0.16px;
      font-weight: 700;
      font-size: 1rem;
      background: transparent;
      border: none;
      border-bottom: 1px solid var(--neutral-200);
      padding: 16px 0 17px;
      width: 100%;
      text-align: left;
      font-family: var(--fs-open-sans);

      &.active {
        :global(.svg-inline--fa) {
          transform: rotate(180deg);
        }
      }

      :global(.svg-inline--fa) {
        margin-left: auto;
      }
    }

    .further-info-content {
      padding: 16px 0;
      font-size: 0.875rem;
      letter-spacing: -0.01em;

      .further-info-introduction {
        font-weight: bold;
      }

      ul {
        margin: 12px 0 0 0;
        list-style-type: none;

        li {
          margin: 0;
        }
      }

      ul + .further-info-introduction {
        margin-top: 32px;
      }
    }
  }

  .donut_chart_container {
    position: relative;

    img {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: helpers.dynamic-size(48, 75);
    }
  }
</style>

<div class="share-distribution">
    <FilterOptions label="Verteilung" selectedValue={filterLabel} on:apply={handleApplyFilter}
                   on:toggle={() => filterIsOpen = !filterIsOpen} isLoading={isAnyLoading}>
        {#each filterOptions as option}
            <DropDownOption
                    value={option.value}
                    name="shareDistribution"
                    bind:group={selectedGroup}
                    label={option.label}
            />
        {/each}
    </FilterOptions>

    <!-- Error Message -->
    {#if errorMessage}
        <p class="error">{errorMessage}</p>
    {:else}
        <div class="share-distribution__cards">
            {#if confirmedGroup === "mix"}
                <div class="row mb-0">
                    <div class="col-6">
                        <div class="shares-total share-distribution__card">
                            <div>
                                <span class="share-distribution__card__label" class:loading-shimmer={isAnyLoading}>Aktien von Vorstand und Aufsichtsrat</span>
                            </div>
                            <div>
                                <span class="share-distribution__card__value"
                                      class:loading-shimmer={isAnyLoading}>{totalSharesBoard}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="shareholders-total share-distribution__card">
                            <div>
                                <span class="share-distribution__card__label" class:loading-shimmer={isAnyLoading}>Aktien von Mitarbeiter:innen</span>
                            </div>
                            <div>
                                <span class="share-distribution__card__value"
                                      class:loading-shimmer={isAnyLoading}>{totalSharesEmployees}</span>
                            </div>
                        </div>
                    </div>
                </div>
            {:else}
                <div class="row mb-0">
                    <div class="col-6">
                        <div class="shares-total share-distribution__card">
                            <div>
                                <span class="share-distribution__card__label"
                                      class:loading-shimmer={isAnyLoading}>Aktien</span>
                            </div>
                            <div>
                                <span class="share-distribution__card__value"
                                      class:loading-shimmer={isAnyLoading}>{totalShares}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="shareholders-total share-distribution__card">
                            <div>
                                <span class="share-distribution__card__label" class:loading-shimmer={isAnyLoading}>Aktionär:innen</span>
                            </div>
                            <div>
                                <span class="share-distribution__card__value"
                                      class:loading-shimmer={isAnyLoading}>{totalShareholders}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="share-capital share-distribution__card">
                            <div>
                                <span class="share-distribution__card__label" class:loading-shimmer={isAnyLoading}>Grundkapital</span>
                            </div>
                            <div>
                                <span class="share-distribution__card__value"
                                      class:loading-shimmer={isAnyLoading}>{shareCapital}</span>
                            </div>
                        </div>
                    </div>
                </div>
            {/if}
        </div>
        {#if isAnyLoading}
            <PlaceholderChart type="pie"/>
        {:else}
            {#if chartData && !errorMessage}
                <div class="donut_chart_container">
                    <Chart type="doughnut" data={chartData} class="share-distribution__chart"
                           options={getChartOptions(chartData, undefined, undefined, false, true, false)}/>
                    <img src="{oeLogo}" alt="oekoenergie AG Logo"/>
                </div>
                <div class="custom-legend">
                    {#each chartData.labels as label, index}
                        <div class="legend-item">
                            <span class="legend-color"
                                  style="background-color: {chartData.datasets[0].backgroundColor[index]};"></span>
                            <span class="legend-value">{chartData.datasets[0].data[index].toLocaleString('de-DE')}
                                Aktien</span>
                            <span class="legend-detail">
                                {chartData.legendDetails[index] || 'Details unavailable'}
                            </span>
                        </div>
                    {/each}
                </div>
                <!-- Add this inside your template where you want to show the transaction data -->
                {#if hasTransactionData && !isAnyLoading && showFurtherInformation}
                    <div class="further-info">
                        <button on:click={toggleTransactionHistoryDisplay} class:active={showTransactionData}
                                aria-expanded={showTransactionData} aria-controls="further-info-dropdown">
                            Weitere Infos
                            <FontAwesomeIcon icon={faChevronDown}/>
                        </button>
                        {#if showTransactionData}
                            <div id="further-info-dropdown" class="further-info-content">
                                {#if transactionsDataBoard && transactionsDataBoard.length > 0}
                                    <p class="further-info-introduction">
                                        Aktuelle Handelsvorgänge von Vorstand und Aufsichtsrat der oekostrom AG:
                                    </p>
                                    <ul>
                                        {#each transactionsDataBoard as transaction}
                                            <li>
                                                <b>{transaction.group || 'Unbekannt'}
                                                    :</b> {transaction.type || 'Transaktion'}
                                                von {transaction.amount || '0'} Aktien, eingetragen in Aktienbuch
                                                am {transaction.date ? new Date(transaction.date).toLocaleDateString('de-DE') : 'unbekanntes Datum'}
                                            </li>
                                        {/each}
                                    </ul>
                                {/if}

                                {#if transactionsDataEmployees && transactionsDataEmployees.length > 0}
                                    <p class="further-info-introduction">
                                        Aktuelle Handelsvorgänge von Mitarbeiter:innen der oekostrom AG:
                                    </p>
                                    <ul>
                                        {#each transactionsDataEmployees as transaction}
                                            <li>
                                                Kauf eines Mitarbeiters von {transaction.amount || '0'} Aktien,
                                                eingetragen in Aktienbuch
                                                am {transaction.date ? new Date(transaction.date).toLocaleDateString('de-DE') : 'unbekanntes Datum'}
                                            </li>
                                        {/each}
                                    </ul>
                                {/if}

                                {#if (!transactionsDataBoard || transactionsDataBoard.length === 0) &&
                                (!transactionsDataEmployees || transactionsDataEmployees.length === 0)}
                                    <p>Keine aktuellen Transaktionsdaten verfügbar.</p>
                                {/if}
                            </div>
                        {/if}
                    </div>
                {/if}
            {/if}
        {/if}
    {/if}
</div>
