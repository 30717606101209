<script>
    export let value = "";
    export let group = "";
    export let label = "";
    export let name = "";
    export let onChange = () => {
    };
    export let onSelect = null;
</script>

<style>
    .dropdown-option {
        display: flex;
    }

    .dropdown-option:not(:last-child) {
        margin-bottom: 16px;
    }

    .dropdown-option--radio input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        cursor: pointer;
        margin: 0;
    }

    .dropdown-option--radio label {
        position: relative;
        padding-left: 29px;
        line-height: 1.5;
        letter-spacing: -0.16px;
        font-weight: 500;
        font-size: 16px;
        color: var(--neutral-800) !important;
        cursor: pointer;
    }

    .dropdown-option--radio label:before,
    .dropdown-option--radio label:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        left: 0;
        top: 2px;
    }

    .dropdown-option--radio label:before {
        border: 1px solid var(--white-bg-grey-border);
    }

    .dropdown-option--radio input:checked+label:after {
        border: 6px solid var(--brand-primary-500);
    }
</style>

<div class="dropdown-option dropdown-option--radio">
    <input
            id="option-{value}"
            type="radio"
            name={name}
            value={value}
            bind:group={group}
            on:change={() => {
                onChange(value);
                if (onSelect) onSelect();
            }}
    />
    <label for="option-{value}">
        {label}
    </label>
</div>
