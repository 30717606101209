const API_PROXY_URL = window.wallstreetConfig.apiProxyUrl;
//console.log("Using API Proxy URL:", API_PROXY_URL);

/**
 * Generic API fetch function.
 *
 * @param {string} endpoint The API endpoint to fetch.
 * @param {Object} params The query parameters to include in the request (optional).
 * @returns {Promise} The API response
 */
const fetchData = async (endpoint, params = {}) => {
    const url = new URL(`${API_PROXY_URL}${endpoint}`);
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    //console.log("Fetching data from API:", url.href);

    try {
        let response = await fetch(url);
        if (!response.ok) {
            //console.error("API Error:", response.status, response.statusText);
            throw new Error(`API Error: ${response.status} ${response.statusText}`);
        }
        response = await response.json();
        //console.log("API response:", response);
        return response;
    } catch (error) {
        //console.error("Error fetching data from API:", error);
        throw error;
    }
};

/**
 * Fetch historic data from the API.
 * @param {number} startYear The starting year for the data.
 * @param {number} endYear The ending year for the data.
 * @param {number} startQuarter The starting quarter for the data.
 * @param {number} endQuarter The ending quarter for the data.
 * @param {null|number} transactionLimit The maximum number of transactions to fetch (optional).
 * @returns {Promise} The API response.
 */
export const getHistoricData = async (
    startYear,
    endYear,
    startQuarter,
    endQuarter,
    transactionLimit = null
) => {
    const params = {
        ...(startYear !== undefined && startYear !== 'undefined' ? { start_year: startYear } : {}),
        ...(endYear !== undefined && endYear !== 'undefined' ? { end_year: endYear } : {}),
        ...(startQuarter !== undefined && startQuarter !== 'undefined' ? { start_quarter: startQuarter } : {}),
        ...(endQuarter !== undefined && endQuarter !== 'undefined' ? { end_quarter: endQuarter } : {}),
        ...(transactionLimit ? { transaction_limit: transactionLimit } : {}),
    };

    return await fetchData("/historicdata", params);
};

/**
 * Fetch share distribution data from the API.
 * @param {string} group The share group to filter (default: "general").
 * @returns {Promise} The API response.
 */
export const getShareDistribution = async (group = "general") => {
    const params = { group };
    return await fetchData("/sharedistribution", params);
};

/**
 * Fetch supply and demand data from the API.
 * @param {number} limit The number of records to fetch (default: 10).
 * @returns {Promise} The API response.
 */
export const getSupplyDemand = async (limit = 10) => {
    const params = { limit };
    return await fetchData("/supplydemand", params);
};
