<script>
    export let title = '';
    export let price = '';
    export let priceChange = '';
    export let priceChangeOutput = '';
    export let isLoading = false;
</script>
<style lang="scss">
  .price-box {
    box-shadow: 0 8px 12px -6px rgba(5, 6, 5, .1);
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: helpers.dynamic-size(12, 16);
    margin-bottom: 16px;

    &__title {
      font-weight: bold;
      letter-spacing: -0.16px;
      line-height: 1.5;
      font-size: helpers.dynamic-size(14, 16);
      color: var(--neutral-500);
      margin-bottom: 8px;
      display: block;
    }

    &__price {
      font-family: var(--fs-tt-firs-neue);
      font-weight: bold;
      line-height: 1;
      font-size: helpers.dynamic-size(40, 65);
      color: var(--neutral-800);
      flex-shrink: 0;
      margin-right: 8px;
    }

    &__change {
      background: var(--brand-primary-200);
      border: 1px solid var(--brand-primary-300);
      border-radius: 4px;
      color: var(--brand-primary-700);
      letter-spacing: -0.12px;
      line-height: 1.333;
      font-size: 0.75rem;
      font-weight: 600;
      padding: 2px 4px 0;
      bottom: helpers.dynamic-size(6, 12);
      position: relative;

      &--negative {
        background: var(--brand-secondary-red-200);
        border-color: var(--brand-secondary-red-300);
        color: var(--brand-secondary-red-700);
      }
    }

    &__container {
      row-gap: 10px;
    }
  }

</style>

<div class="price-box">
    <span class="price-box__title d-inline-block" class:loading-shimmer={isLoading}>{title}</span>
    <div class="price-box__container d-flex align-items-end flex-wrap">
        <span class="price-box__price" class:loading-shimmer={isLoading}>{price}</span>
        {#if priceChange && priceChangeOutput && !isLoading}
            <span class="price-box__change {priceChange < 0 ? 'price-box__change--negative' : ''}">
                {priceChange >= 0 ? "+" : ""}{priceChangeOutput}
            </span>
        {/if}
    </div>
</div>