<script>
    import {onMount} from "svelte";
    import Chart from "../elements/Chart.svelte";
    import FilterOptions from "../elements/FilterOptions.svelte";
    import {
        fetchApiYearQuarterInfo,
        fetchData,
        getChartOptions,
        getChartDataset,
        OEKOSTROM_COLORS
    } from "../js/sharedFunctions.js";
    import DropDownOption from "../elements/DropDownOption.svelte";
    import PriceBox from "../elements/PriceBox.svelte";
    import {faCalendarClock} from "@fortawesome/pro-solid-svg-icons";
    import SelectField from "../elements/SelectField.svelte";
    import PlaceholderChart from "../elements/PlaceholderChart.svelte";

    // Base variables.
    let chartData = null;
    const now = new Date();
    const currentYear = now.getFullYear();

    // Default timeframe
    let selectedTimeframe = "five-years";

    // Loading state.
    let isLoading = true;

    // Selected years for custom timeframe - these are OBJECTS, not numbers!
    let selectedStartYearObj = null;
    let selectedEndYearObj = null;

    // Default values for earliest/latest year.
    let earliestYear = currentYear;
    let latestYear = currentYear;

    // The entire scope of the API data as far as timeframe goes.
    let apiEarliestYear = currentYear;
    let apiLatestYear = currentYear;

    // Available year options for dropdowns
    let startYearOptions = [];
    let endYearOptions = [];

    // Helper flags to prevent reactive loops
    let updatingFromTimeframe = false;
    let updatingFromDropdown = false;
    let initializing = true;

    // We need to know which options are available for the user to select
    let threeYearsAvailable = false;
    let fiveYearsAvailable = false;
    let tenYearsAvailable = false;

    // Function to determine start year based on timeframe
    function getComputedStartYear(tf) {
        const timeframe = tf || selectedTimeframe;

        // Calculate the appropriate start year based on timeframe
        let startYear;

        if (timeframe === "three-years") {
            startYear = apiLatestYear - 3 + 1;
        } else if (timeframe === "five-years") {
            startYear = apiLatestYear - 5 + 1;
        } else if (timeframe === "ten-years") {
            startYear = apiLatestYear - 10 + 1;
        } else if (timeframe === "all-time") {
            startYear = apiEarliestYear;
        } else {
            startYear = apiLatestYear;
        }

        // Make sure we don't go earlier than the earliest available year
        return Math.max(startYear, apiEarliestYear);
    }

    // Function to check if years match a predefined timeframe
    function checkIfMatchesTimeframe(startYear, endYear) {
        if (endYear !== apiLatestYear) return false;

        if (startYear === apiLatestYear - 3 + 1) return "three-years";
        if (startYear === apiLatestYear - 5 + 1) return "five-years";
        if (startYear === apiLatestYear - 10 + 1) return "ten-years";
        if (startYear === apiEarliestYear) return "all-time";

        return false;
    }

    // Generate option objects for all available years
    function generateYearOptions() {
        const allYears = [];
        for (let year = apiLatestYear; year >= apiEarliestYear; year--) {
            allYears.push({
                value: year,
                label: String(year)
            });
        }
        return allYears;
    }

    // Update both start and end year options
    function updateOptions() {
        // Update start year options
        startYearOptions = generateYearOptions();

        // Update end year options based on selected start year
        updateEndOptions();
    }

    // Update end year options based on selected start year
    function updateEndOptions() {
        if (selectedStartYearObj) {
            // Filter end options to only show years >= selected start year
            endYearOptions = generateYearOptions().filter(
                opt => opt.value >= selectedStartYearObj.value
            );
        } else {
            endYearOptions = generateYearOptions();
        }
    }

    // Helper to find the option object for a given year
    function findYearOption(year) {
        for (const opt of startYearOptions) {
            if (opt.value === year) {
                return opt;
            }
        }
        return null;
    }

    let currentPrice = null;
    let outputCurrentPrice = "N/A";

    // Store the latest year's dividend value
    let latestYearDividend = null;

    const fetchDividendData = async () => {

        isLoading = true;

        // Get actual year values for the API call
        const startYear = selectedStartYearObj ? selectedStartYearObj.value : getComputedStartYear();
        const endYear = selectedEndYearObj ? selectedEndYearObj.value : apiLatestYear;

        // Always fetch data including the latest year, regardless of filter
        // This ensures we always have the latest dividend data
        const fetchLatest = async () => {
            // We need to always include the latest year in our fetch to show current price
            const fetchEndYear = Math.max(endYear, apiLatestYear);
            return await fetchData(
                startYear,
                fetchEndYear,
                undefined,
                undefined,
                "dividends"
            );
        };

        // Fetch data up to the latest year available in the API
        ({chartData, earliestYear, isLoading} = await fetchLatest());

        // Variable to hold filtered chart data
        let filteredChartData = null;
        let barChartData = [];  // For payout ratio
        let lineChartData = []; // For dividend per share
        let labels = [];  // Years

        // Check if chart data exists
        if (chartData?.labels?.length) {
            // Create an array with years and their corresponding dividend values
            const allData = chartData.labels.map((label, index) => {
                const year = parseInt(label, 10);
                const dividendData = chartData.datasets[0]?.data[index] ?? {};

                // Extract both dividend_per_share and payout_ratio_percentage directly from `dividendData`
                const payoutRatio = dividendData?.payout_ratio_percentage ?? 0;
                const dividendPerShare = dividendData?.dividend_per_share ?? 0;

                labels.push(year);
                barChartData.push(payoutRatio);
                lineChartData.push(dividendPerShare);

                return {
                    label,
                    index,
                    year,
                    payoutRatio,
                    dividendPerShare
                };
            });

            // Extract the dividend for the latest API year directly - always keep this
            const latestYearData = allData.find(d => d.year === apiLatestYear);
            if (latestYearData) {
                latestYearDividend = latestYearData.dividendPerShare ?? 0;
            }

            // Filter the data based on the selected year range for chart display
            const filteredData = allData.filter(d => d.year >= startYear && d.year <= endYear);

            // Rebuild chart data arrays based on filtered data
            labels = filteredData.map(d => d.year);
            barChartData = filteredData.map(d => d.payoutRatio);
            lineChartData = filteredData.map(d => d.dividendPerShare);

            // Create filtered chart data using getChartDataset
            filteredChartData = {
                labels,
                datasets: [
                    // Bar chart for payout ratio
                    {
                        ...getChartDataset(
                            barChartData,
                            "bar",
                            "Ausschüttungsquote in %",
                            OEKOSTROM_COLORS.grey,
                            "#ffffff",
                            OEKOSTROM_COLORS.grey,
                            "0.3",
                            "percentage"
                        ),
                        yAxisID: "y"
                    },
                    // Line chart for dividend per share
                    {
                        ...getChartDataset(
                            lineChartData,
                            "line",
                            "Dividende/Aktie in EUR",
                            OEKOSTROM_COLORS.violet,
                            "#ffffff",
                            OEKOSTROM_COLORS.violet,
                            "0.2",
                            "currency"
                        ),
                        yAxisID: "yDividends",
                    }
                ]
            };

            // Determine the latest year with actual data in the filtered dataset
            const lastAvailableData = filteredData.slice().reverse().find(d => d.dividendPerShare !== 0);
            latestYear = lastAvailableData ? lastAvailableData.year : Math.max(...filteredData.map(d => d.year));
        }

        // Always set currentPrice based on the latest year dividend, not the filtered data
        currentPrice = latestYearDividend ?? 0;

        // Ensure dividend price is formatted properly
        outputCurrentPrice = currentPrice
            ? currentPrice.toLocaleString('de-AT', {style: 'currency', currency: 'EUR'})
            : "N/A";

        // Update chartData with the filtered data
        chartData = filteredChartData;

        isLoading = false;
    };

    function handleApplyFilter() {
        fetchDividendData();
    }

    // Ensure that we select custom when the user has manually changed the start/end years.
    let isCustomSelection = false;

    // Handle start year selection change
    function handleStartYearChange() {
        if (updatingFromTimeframe) return;

        updatingFromDropdown = true;

        // Check if selection matches a predefined timeframe
        if (selectedStartYearObj && selectedEndYearObj) {
            const matchingTimeframe = checkIfMatchesTimeframe(
                selectedStartYearObj.value,
                selectedEndYearObj.value
            );

            if (matchingTimeframe) {
                // If it matches a predefined timeframe, use that
                selectedTimeframe = matchingTimeframe;
                isCustomSelection = false;
            } else {
                // Otherwise, it's a custom selection
                selectedTimeframe = "custom";
                isCustomSelection = true;
            }
        } else {
            // Default to custom if we can't determine
            selectedTimeframe = "custom";
            isCustomSelection = true;
        }

        // Update end year options
        updateEndOptions();

        // Make sure end year is valid
        if (selectedEndYearObj && selectedStartYearObj &&
            selectedEndYearObj.value < selectedStartYearObj.value) {
            selectedEndYearObj = selectedStartYearObj;
        }

        updatingFromDropdown = false;
    }

    // Handle end year selection change
    function handleEndYearChange() {
        if (updatingFromTimeframe) return;

        updatingFromDropdown = true;

        // Check if selection matches a predefined timeframe
        if (selectedStartYearObj && selectedEndYearObj) {
            const matchingTimeframe = checkIfMatchesTimeframe(
                selectedStartYearObj.value,
                selectedEndYearObj.value
            );

            if (matchingTimeframe) {
                // If it matches a predefined timeframe, use that
                selectedTimeframe = matchingTimeframe;
                isCustomSelection = false;
            } else {
                // Otherwise, it's a custom selection
                selectedTimeframe = "custom";
                isCustomSelection = true;
            }
        } else {
            // Default to custom if we can't determine
            selectedTimeframe = "custom";
            isCustomSelection = true;
        }

        updatingFromDropdown = false;
    }

    // Update dropdowns when timeframe changes
    function updateDropdownsFromTimeframe() {
        if (updatingFromDropdown) return;

        updatingFromTimeframe = true;

        // Get years based on selected timeframe
        const startYear = getComputedStartYear();
        const endYear = apiLatestYear;

        // Ensure our startYear options are up to date
        updateOptions();

        // Find the corresponding option objects
        const startObj = findYearOption(startYear);
        const endObj = findYearOption(endYear);

        // Update dropdown selections
        if (startObj) selectedStartYearObj = startObj;
        if (endObj) selectedEndYearObj = endObj;

        // Update end year options based on the new selection
        updateEndOptions();

        // Reset custom selection state
        isCustomSelection = selectedTimeframe === "custom";

        updatingFromTimeframe = false;
    }

    // Handle timeframe radio button change
    function handleTimeframeChange(value) {
        if (updatingFromDropdown) return;

        selectedTimeframe = value;
        isCustomSelection = value === "custom";

        // Update dropdowns to match the new timeframe
        if (value !== "custom") {
            updateDropdownsFromTimeframe();
        }
    }

    // Update the filter label
    $: filterLabel = isCustomSelection
        ? `${selectedStartYearObj ? selectedStartYearObj.value : ''} – ${selectedEndYearObj ? selectedEndYearObj.value : ''}`
        : (
            selectedTimeframe === "three-years" ? "Letzte 3 Jahre" :
                selectedTimeframe === "five-years" ? "Letzte 5 Jahre" :
                    selectedTimeframe === "ten-years" ? "Letzte 10 Jahre" :
                        "Gesamtzeitraum"
        );

    // When selectedStartYearObj changes, update end options
    $: if (selectedStartYearObj && !updatingFromTimeframe && !initializing) {
        updateEndOptions();
    }

    // When timeframe changes, update dropdowns
    $: if (selectedTimeframe && !updatingFromDropdown && !initializing) {
        if (selectedTimeframe !== "custom") {
            updateDropdownsFromTimeframe();
        }
    }

    // We need to know if we have the necessary data for each timeframe.
    function updateAvailableTimeframes() {
        // Calculate how many years of data we have...
        const yearsOfData = apiLatestYear - apiEarliestYear + 1;

        // Check if we have enough data for each timeframe...
        threeYearsAvailable = yearsOfData >= 3;
        fiveYearsAvailable = yearsOfData >= 5;
        tenYearsAvailable = yearsOfData >= 10;

        // If the selected timeframe is not available, default to "all-time"...
        if (selectedTimeframe === "three-years" && !threeYearsAvailable ||
            selectedTimeframe === "five-years" && !fiveYearsAvailable ||
            selectedTimeframe === "ten-years" && !tenYearsAvailable) {
            selectedTimeframe = "all-time";
            isCustomSelection = false;

            // Update dropdowns to match the new timeframe
            updateDropdownsFromTimeframe();
        }
    }

    onMount(async () => {
        initializing = true;

        const apiInfo = await fetchApiYearQuarterInfo();
        if (apiInfo) {
            apiEarliestYear = apiInfo.apiEarliestYear;
            apiLatestYear = apiInfo.apiLatestYear;

            // Determine which timeframe options are available
            updateAvailableTimeframes();

            // Generate initial options
            updateOptions();

            // Set initial values based on default timeframe
            const startYear = getComputedStartYear();
            const endYear = apiLatestYear;

            // Find corresponding objects
            selectedStartYearObj = findYearOption(startYear);
            selectedEndYearObj = findYearOption(endYear);

            // Make sure the timeframe and custom selection state are correct
            isCustomSelection = selectedTimeframe === "custom";
        }

        await fetchDividendData();

        // After initialization is complete, set flag to false
        setTimeout(() => {
            initializing = false;
        }, 100);
    });
</script>

<style lang="scss">
  .filter-section {
    display: flex;
    align-items: center;

    :global(.oekp_select) {
      flex: 1;
    }

    > span {
      flex-shrink: 0;
      margin: 0 helpers.dynamic-size(8, 15);
    }
  }

  .fieldset {
    > span {
      font-size: 0.75rem;
      font-weight: bold;
      display: block;
      letter-spacing: -0.12px;
      color: var(--neutral-500);
      line-height: 1.5;
      margin-bottom: 8px;
    }

    + .fieldset {
      border-top: 1px solid var(--neutral-200);
      margin-top: 16px;
      padding-top: 12px;
    }
  }
</style>

<div class="dividends">
    <!-- Current Dividend Box -->
    <PriceBox title="Dividendenpreis · {apiLatestYear}" price="{outputCurrentPrice}*" isLoading={isLoading}/>

    <!-- Filter box -->
    <FilterOptions label="Anzeige & Zeitraum" selectedValue={filterLabel} on:apply={handleApplyFilter}
                   icon="{faCalendarClock}">
        <!-- Radio buttons for timeframe selection -->
        <div class="fieldset">
            <span>Zeitraum</span>

            {#if threeYearsAvailable}
                <DropDownOption
                        value="three-years"
                        name="dividends"
                        group={selectedTimeframe}
                        label="Letzte 3 Jahre"
                        onChange={handleTimeframeChange}
                />
            {/if}

            {#if fiveYearsAvailable}
                <DropDownOption
                        value="five-years"
                        name="dividends"
                        group={selectedTimeframe}
                        label="Letzte 5 Jahre"
                        onChange={handleTimeframeChange}
                />
            {/if}

            {#if tenYearsAvailable}
                <DropDownOption
                        value="ten-years"
                        name="dividends"
                        group={selectedTimeframe}
                        label="Letzte 10 Jahre"
                        onChange={handleTimeframeChange}
                />
            {/if}

            <DropDownOption
                    value="all-time"
                    name="dividends"
                    group={selectedTimeframe}
                    label="Gesamtzeitraum"
                    onChange={handleTimeframeChange}
            />

            <DropDownOption
                    value="custom"
                    name="dividends"
                    group={selectedTimeframe}
                    label="Benutzerdefiniert"
                    onChange={handleTimeframeChange}
            />
        </div>

        <div class="fieldset">
            <span>Zeitraum nach Jahren</span>
            <!-- Year filter -->
            <div class="filter-section">
                <SelectField
                        label="Von"
                        name="dividends-year-select-from"
                        options={startYearOptions}
                        bind:value={selectedStartYearObj}
                        on:change={handleStartYearChange}
                />
                <span>bis</span>
                <SelectField
                        label="Bis"
                        name="dividends-year-select-to"
                        options={endYearOptions}
                        bind:value={selectedEndYearObj}
                        on:change={handleEndYearChange}
                />
            </div>
        </div>
    </FilterOptions>

    {#if isLoading}
        <PlaceholderChart type="bar"/>
    {:else}
        {#if chartData}
            <Chart
                    type="bar"
                    data={chartData}
                    options={getChartOptions(
                    chartData,
                    "Year",
                    "Ausschüttungsquote in %",
                    false,
                    false,
                    true,
                    "year",
                    OEKOSTROM_COLORS.violet,
                    "bar"
                )}
            />
        {/if}
    {/if}
</div>