<script>
    import {createEventDispatcher} from 'svelte';
    import {FontAwesomeIcon} from "@fortawesome/svelte-fontawesome";
    import {faBarsFilter, faChevronDown} from "@fortawesome/pro-solid-svg-icons";
    import slugify from "slugify";

    export let label = "";
    export let selectedValue = "Filter anzeigen";
    export let icon = "";
    export let isLoading = false;

    let showFilter = false;
    const dispatch = createEventDispatcher();

    function toggleFilter() {
        showFilter = !showFilter;
        dispatch("toggle", showFilter);
    }

    function applyFilter() {
        dispatch("apply");
        showFilter = false;
        dispatch("toggle", showFilter);
    }

    // If we click outside of the filter, it should also be closed.
    document.addEventListener('click', function (event) {
        const target = event.target;
        if (target instanceof HTMLElement && !target.closest('.filter-dropdown')) {
            showFilter = false;
        }
    });
</script>

<style lang="scss">
  .filter-dropdown {
    position: relative;
    cursor: pointer;
    font-family: var(--fs-open-sans);
    margin-bottom: 16px;
  }

  .filter-dropdown > button {
    display: flex;
    align-items: center;
    background: none;
    width: 100%;
    border: 1px solid var(--white-bg-grey-border);
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 14px;
    font-family: var(--fs-tt-firs-neue);
    color: var(--neutral-800) !important;

    &:disabled {
      cursor: not-allowed;
    }

    &.loading-shimmer {
      color: transparent !important;
      border-radius: 6px;
      background: #eee;
      background: linear-gradient(100deg, #eee 0%, #eee 45%, #fefefe 50%, #eee 55%, #eee 100%);
      background-repeat: repeat-x;
      background-size: 300%;
      background-position-x: 0%;
      user-select: none !important;
      animation: shimmer-animation 1s infinite;
      min-width: 50px;
    }
  }

  :global(.filter-dropdown button span) {
    position: relative;
    top: 1px;
    margin-right: 6px;
  }

  :global(.filter-dropdown button .svg-inline--fa) {
    margin-right: 6px;
  }

  :global(.filter-dropdown button .svg-inline--fa:first-child) {
    color: var(--neutral-500);
  }

  :global(.filter-dropdown button span + .svg-inline--fa) {
    margin-left: auto;
    margin-right: 0;
  }

  .filter-dropdown,
  .dropdown-menu {
    width: 100%;
  }

  .dropdown-menu {
    position: absolute;
    margin-top: 5px;
    top: 100%;
    left: 0;
    background: var(--white-bg);
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu .dropdown-menu__content {
    padding: 12px 16px;
  }

  .dropdown-menu .dropdown-menu__footer {
    padding: 9px 8px 8px;
    border-top: 1px solid var(--neutral-200);
  }

  button.apply-filter {
    font-size: 12px;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  :global(.dropdown-option:not(:last-child)) {
    margin-bottom: 16px;
  }

  .button-label {
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: -0.12px;
    color: var(--neutral-500);
    line-height: 1.5;
    margin-bottom: 8px;
    display: block;
  }

  @keyframes shimmer-animation {
    0% {
      background-position-x: 0%;
    }

    100% {
      background-position-x: 100%;
    }
  }
</style>

<div class="filter-dropdown">
    {#if label}
        <span id="describe-dropdown-{slugify(label)}" class="button-label">{label}</span>
    {/if}
    <button aria-describedby="describe-dropdown-{label ? slugify(label) : null}" on:click={toggleFilter}
            aria-expanded={showFilter} aria-controls="dropdown-{slugify(label)}" class:loading-shimmer={isLoading}
            disabled={isLoading}>
        {#if icon}
            <FontAwesomeIcon icon={icon}/>
        {:else}
            <FontAwesomeIcon icon={faBarsFilter}/>
        {/if}
        <span>{selectedValue}</span>
        <FontAwesomeIcon icon={faChevronDown}/>
    </button>
    {#if showFilter}
        <div id="dropdown-{slugify(label)}" class="dropdown-menu">
            <div class="dropdown-menu__content">
                <slot/>
            </div>
            <div class="dropdown-menu__footer">
                <button class="avia-button avia-color-theme-color apply-filter" on:click={applyFilter}>
                    Filter anwenden
                </button>
            </div>
        </div>
    {/if}
</div>
