<script>
    import lineChartPlaceholder from "../../images/chart-line.svg";
    import barChartPlaceholder from "../../images/chart-bar.svg";
    import pieChartPlaceholder from "../../images/chart-pie.svg";
    import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/svelte-fontawesome";

    export let type = 'line';
</script>
<style>
    .loading-chart {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;

        :global(.svg-inline--fa) {
            position: absolute;
        }
    }
</style>

<div class="loading-chart">
    {#if type === 'bar'}
        <img src="{barChartPlaceholder}" alt="Bar Chart wird geladen...">
    {:else if type === 'pie'}
        <img src="{pieChartPlaceholder}" alt="Pie Chart wird geladen...">
    {:else}
        <img src="{lineChartPlaceholder}" alt="Line Chart wird geladen...">
    {/if}
    <FontAwesomeIcon icon="{faSpinner}" class="fa-spin-pulse"/>
</div>