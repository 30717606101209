import { getHistoricData } from "./apiHandler.js";
import {
    customTickCircles,
    externalTooltipHandler,
    createNeatNumber
} from "./chartJsPlugins.js";

let cachedApiInfo = null;
let cacheTimestamp = 0;
const CACHE_DURATION_MS = 2 * 60 * 1000; // 2 minutes cache duration

export const OEKOSTROM_COLORS = {
    "green": "rgba(57,163,114,1)",
    "blue": "rgba(79,193,211,1)",
    "violet": "rgba(205,141,234,1)",
    "yellow": "rgba(251,221,67,1)",
    "darkgreen": "rgba(25,81,56,1)",
    "orange": "rgba(226,112,100,1)",
    "grey": "rgba(115,120,118,1)"
};

/**
 * Check if the selected date range matches a predefined timeframe
 * @param {String} selectedStartOption - Selected start quarter in format "quarter/year"
 * @param {String} selectedEndOption - Selected end quarter in format "quarter/year"
 * @param {Number} apiEarliestYear - Earliest year available in the API
 * @param {Number} apiEarliestQuarter - Earliest quarter available in the API
 * @param {Number} apiLatestYear - Latest year available in the API
 * @param {Number} apiLatestQuarter - Latest quarter available in the API
 * @param {Object} timeframeQuarters - Mapping of timeframe names to number of quarters
 * @returns {String} The matching timeframe name or "custom" if no match
 */
export function getMatchingTimeframe(
    selectedStartOption,
    selectedEndOption,
    apiEarliestYear,
    apiEarliestQuarter,
    apiLatestYear,
    apiLatestQuarter,
    timeframeQuarters
) {
    if (!selectedStartOption || !selectedEndOption) {
        return null;
    }

    // Check "all-time" match
    if (selectedStartOption === `${apiEarliestQuarter}/${apiEarliestYear}` &&
        selectedEndOption === `${apiLatestQuarter}/${apiLatestYear}`) {
        return "all-time";
    }

    // Check for fixed timeframes
    for (const [timeframe, quarters] of Object.entries(timeframeQuarters)) {
        const options = getLastNQuarters(quarters, apiLatestYear, apiLatestQuarter);
        if (options.length > 0 &&
            selectedStartOption === options[0].value &&
            selectedEndOption === options[options.length - 1].value) {
            return timeframe;
        }
    }

    // If no match found
    return "custom";
}

/**
 * Filter quarter options to only include those after a selected start quarter
 * @param {Array} allOptions - All available quarter options
 * @param {String} selectedStartOption - The selected start quarter in format "quarter/year"
 * @returns {Array} Filtered options that come after the selected start
 */
export function filterEndQuarterOptions(allOptions, selectedStartOption) {
    if (!selectedStartOption) {
        return [...allOptions];
    }

    const [startQuarter, startYear] = selectedStartOption.split("/").map(Number);

    return allOptions.filter(option => {
        const [optionQuarter, optionYear] = option.value.split("/").map(Number);
        return (optionYear > startYear) ||
            (optionYear === startYear && optionQuarter >= startQuarter);
    });
}

/**
 * Get the appropriate dropdown values for a selected timeframe
 * @param {String} timeframe - The selected timeframe (e.g., "three-years", "five-years", "all-time", "custom")
 * @param {Object} timeframeQuarters - Mapping of timeframe names to number of quarters
 * @param {Number} apiEarliestYear - Earliest year available in the API
 * @param {Number} apiEarliestQuarter - Earliest quarter available in the API
 * @param {Number} apiLatestYear - Latest year available in the API
 * @param {Number} apiLatestQuarter - Latest quarter available in the API
 * @param {String} currentStartOption - Current start option in format "quarter/year" (used for custom timeframe)
 * @param {String} currentEndOption - Current end option in format "quarter/year" (used for custom timeframe)
 * @returns {Object} Object with startOption and endOption properties
 */
export function getDropdownValuesForTimeframe(
    timeframe,
    timeframeQuarters,
    apiEarliestYear,
    apiEarliestQuarter,
    apiLatestYear,
    apiLatestQuarter,
    currentStartOption = "",
    currentEndOption = ""
) {
    if (timeframe === "custom") {
        // For custom, keep current selections if they exist and are valid
        if (currentStartOption && currentEndOption) {
            return {
                startOption: currentStartOption,
                endOption: currentEndOption
            };
        } else {
            // Default to 5 years if no selection
            const options = getLastNQuarters(
                timeframeQuarters["five-years"] || 20,
                apiLatestYear,
                apiLatestQuarter
            );

            if (options.length > 0) {
                return {
                    startOption: options[0].value,
                    endOption: options[options.length - 1].value
                };
            }
        }
    } else if (timeframe === "all-time") {
        // For all-time, use full API range
        return {
            startOption: `${apiEarliestQuarter}/${apiEarliestYear}`,
            endOption: `${apiLatestQuarter}/${apiLatestYear}`
        };
    } else {
        // Fixed timeframes (one-year, three-years, five-years)
        const quarters = timeframeQuarters[timeframe];
        const options = getLastNQuarters(quarters, apiLatestYear, apiLatestQuarter);

        if (options.length > 0) {
            return {
                startOption: options[0].value,
                endOption: options[options.length - 1].value
            };
        }
    }

    // Fallback in case of errors
    return {
        startOption: currentStartOption,
        endOption: currentEndOption
    };
}

/**
 * Get a filter label based on the selected timeframe and options
 * @param {String} timeframe - The selected timeframe
 * @param {String} startOption - Start quarter in format "quarter/year"
 * @param {String} endOption - End quarter in format "quarter/year"
 * @returns {String} Descriptive label for the filter
 */
export function getFilterLabel(timeframe, startOption, endOption) {
    return timeframe === "custom"
        ? `Q${startOption} – Q${endOption}`
        : timeframe === "one-year"
            ? "Letztes Jahr"
            : timeframe === "three-years"
                ? "Letzte 3 Jahre"
                : timeframe === "five-years"
                    ? "Letzte 5 Jahre"
                    : "Gesamtzeitraum";
}

/**
 * Fetches earliest and latest years and quarters from the API and caches the result for 2 minutes.
 * @returns {Promise<Object>} An object containing apiEarliestYear, apiLatestYear, apiEarliestQuarter, and apiLatestQuarter.
 */
export const fetchApiYearQuarterInfo = async () => {
    const now = Date.now();

    // Check if cached data is still valid
    if (cachedApiInfo && (now - cacheTimestamp < CACHE_DURATION_MS)) {
        return cachedApiInfo;
    }

    const response = await getHistoricData(undefined, undefined, undefined, undefined, 200);

    if (!response?.response?.years?.length) {
        console.error("Invalid API response:", response);
        return null;
    }

    const yearsData = response.response.years;

    // Extract earliest and latest years
    const apiEarliestYear = Math.min(...yearsData.map(y => y.year));
    const apiLatestYear = Math.max(...yearsData.map(y => y.year));

    // Extract earliest and latest quarters
    let apiEarliestQuarter = 1;
    let apiLatestQuarter = 4;

    yearsData.forEach(year => {
        if (year.year === apiEarliestYear) {
            apiEarliestQuarter = Math.min(...year.quarters.map(q => q.quarter));
        }
        if (year.year === apiLatestYear) {
            apiLatestQuarter = Math.max(...year.quarters.map(q => q.quarter));
        }
    });

    const apiInfo = {
        apiEarliestYear,
        apiLatestYear,
        apiEarliestQuarter,
        apiLatestQuarter
    };

    // Cache the result
    cachedApiInfo = apiInfo;
    cacheTimestamp = now;

    return apiInfo;
};

/**
 * Function to fetch historical data and extract chart-related information.
 *
 * @param {number} startYear (Optional) Start year for data filtering.
 * @param {number} endYear (Optional) End year for data filtering.
 * @param {number} startQuarter (Optional) Start quarter for trading volume.
 * @param {number} endQuarter (Optional) End quarter for trading volume.
 * @param {string} type Type of data to fetch ("dividends", "tradingVolume", etc.).
 * @returns {Promise<Object>} Processed chart data.
 */
export const fetchData = async (startYear, endYear, startQuarter, endQuarter, type) => {
    let loading = true;
    let chartData = null;
    let earliestYear = new Date().getFullYear();
    let earliestQuarter = 1;
    let latestYear = new Date().getFullYear();
    let latestQuarter = 4;
    let currentPrice = null;
    let priceChange = null;
    let currentVolume = null;
    let volumeChange = null;

    try {
        // Fetch full and filtered data
        const full_data = await getHistoricData(undefined, undefined, undefined, undefined, 200);
        const data = await getHistoricData(startYear, endYear, startQuarter, endQuarter);

        // Validate responses
        if (!data.response || !data.response.years) {
            console.error(`Invalid API response for ${type}:`, data);
            return { chartData: null, earliestYear, earliestQuarter, currentPrice, priceChange, loading: false };
        }

        if (!full_data.response || !full_data.response.years) {
            console.error(`Invalid full API response when fetching all data for ${type}:`, full_data);
            return { chartData: null, earliestYear, earliestQuarter, currentPrice, priceChange, loading: false };
        }

        // Get the latest year and latest quarter (i.e. the most recent ones).
        const sortedYears = full_data.response.years.sort((a, b) => b.year - a.year);
        if (sortedYears.length) {
            latestYear = sortedYears[0].year;
            const sortedQuarters = sortedYears[0].quarters.sort((a, b) => b.quarter - a.quarter);
            if (sortedQuarters.length) {
                latestQuarter = sortedQuarters[0].quarter;
                currentPrice = sortedQuarters[0].share_value;
                priceChange = sortedQuarters[0].share_percentage_to_previous_year;
                currentVolume = sortedQuarters[0].volume;
                volumeChange = sortedQuarters[0].volume_percentage_to_previous_year;
            }
        }

        // Get the latest available share price (most recent quarter).
        if (type === "sharePrices" || type === "dividends") {
            const latestYearData = full_data.response.years.reduce((latest, yearObj) => {
                if (!latest || yearObj.year > latest.year) {
                    return yearObj;
                }
                return latest;
            }, null);

            if (latestYearData?.quarters?.length) {
                const latestQuarterData = latestYearData.quarters.reduce((latest, quarter) => {
                    if (!latest || quarter.quarter > latest.quarter) {
                        return quarter;
                    }
                    return latest;
                }, null);

                if (latestQuarterData) {
                    latestYear = latestYearData.year;
                    latestQuarter = latestQuarterData.quarter;
                    currentPrice = latestQuarterData.share_value;
                    priceChange = latestQuarterData.share_percentage_to_previous_year;
                    currentVolume = latestQuarterData.volume;
                    volumeChange = latestQuarterData.volume_percentage_to_previous_year;
                }
            }
        }

        // Get the earliest available year and quarter
        earliestYear = Math.min(...full_data.response.years.map(y => y.year));
        if (type === "tradingVolume" || type === "sharePrices") {
            const earliestOption = full_data.response.years.reduce((acc, yearObj) => {
                if (!yearObj.quarters || yearObj.quarters.length === 0) return acc;
                const minQuarter = Math.min(...yearObj.quarters.map(q => q.quarter));
                const candidate = { year: yearObj.year, quarter: minQuarter };
                if (!acc || candidate.year < acc.year || (candidate.year === acc.year && candidate.quarter < acc.quarter)) {
                    return candidate;
                }
                return acc;
            }, null);
            if (earliestOption) {
                earliestYear = earliestOption.year;
                earliestQuarter = earliestOption.quarter;
            }
        }

        // Initialize arrays for chart labels and data
        const labels = [];
        const dataValues = [];
        const dataMap = new Map(); // Store data for quick lookup

        // Populate map with API data
        data.response.years.sort((a, b) => a.year - b.year).forEach((year) => {
            if (type === "dividends") {
                // Store both dividend_per_share and payout_ratio_percentage for each year
                dataMap.set(year.year.toString(), {
                    dividend_per_share: year.dividend?.[0]?.dividend_per_share ?? 0,
                    payout_ratio_percentage: year.dividend?.[0]?.payout_ratio_percentage ?? 0
                });
            } else {
                // Store quarterly data (for sharePrices & tradingVolume)
                year.quarters?.forEach((quarter) => {
                    const label = `Q${quarter.quarter}/${year.year}`;
                    dataMap.set(label, type === "tradingVolume" ? quarter.volume : quarter.share_value);
                });
            }
        });

        // Generate labels correctly
        if (type === "dividends") {
            // Only include years that exist in the dataMap...
            const availableYears = Array.from(dataMap.keys()).map(Number).sort((a, b) => a - b);
            availableYears.forEach(year => {
                if (year >= startYear && year <= endYear) {
                    labels.push(year.toString());
                    dataValues.push(dataMap.get(year.toString()));
                }
            });
        } else {
            // Only use quarters that exist in the API response...
            data.response.years.forEach((year) => {
                year.quarters?.forEach((quarter) => {
                    const label = `Q${quarter.quarter}/${year.year}`;
                    if (dataMap.has(label)) {
                        labels.push(label);
                        dataValues.push(dataMap.get(label));
                    }
                });
            });
        }

        // Prepare chart data
        chartData = {
            labels,
            datasets: [
                {
                    label: type === "dividends" ? "Dividende/Aktie in EUR" : type === "tradingVolume" ? "Handelsvolumen in EUR" : "Preis/Aktie in EUR",
                    data: dataValues,
                    backgroundColor: type === "dividends" ? OEKOSTROM_COLORS.violet
                        : type === "tradingVolume" ? OEKOSTROM_COLORS.blue
                            : OEKOSTROM_COLORS.darkgreen,
                    borderColor: type === "dividends" ? OEKOSTROM_COLORS.violet
                        : type === "tradingVolume" ? OEKOSTROM_COLORS.blue
                            : OEKOSTROM_COLORS.green,
                    borderWidth: 2,
                    fill: type === "sharePrices",
                    roundFunction: createNeatNumber,
                },
            ],
        };
    } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
    }

    loading = false;

    // Only return `currentPrice` & `priceChange` for sharePrices...
    let return_data = "";

    if(type === "sharePrices") {
        return_data = { chartData, earliestYear, earliestQuarter, latestYear, latestQuarter, currentPrice, priceChange, loading };
    } else if(type === "tradingVolume") {
        return_data = { chartData, earliestYear, earliestQuarter, latestYear, latestQuarter, currentVolume, volumeChange, loading };
    } else {
        return_data = { chartData, earliestYear, latestYear, earliestQuarter, loading };
    }

    return return_data;
};

/**
 * Function to generate an array of quarter/year options in ascending order.
 */
export function getQuarterYearOptions(startYear, startQuarter, endYear, endQuarter, apiEarliestYear, apiEarliestQuarter, apiLatestYear, apiLatestQuarter) {
    const options = [];
    for (let year = startYear; year <= endYear; year++) {
        const qStart = (year === apiEarliestYear) ? apiEarliestQuarter : (year === startYear ? startQuarter : 1);
        const qEnd = (year === apiLatestYear) ? apiLatestQuarter : (year === endYear ? endQuarter : 4);
        for (let q = qStart; q <= qEnd; q++) {
            options.push({
                label: `Q${q}/${year}`,
                value: `${q}/${year}`,
                quarter: q,
                year
            });
        }
    }
    return options;
}

/**
 * Function to generate an array of the last N quarters in ascending order.
 */
export function getLastNQuarters(n, apiLatestYear, apiLatestQuarter) {
    const options = [];
    let year = apiLatestYear;
    let quarter = apiLatestQuarter;

    // Generate the last N quarters
    for (let i = 0; i < n; i++) {
        options.push({
            label: `Q${quarter}/${year}`,
            value: `${quarter}/${year}`,
            quarter,
            year
        });

        // Move back one quarter
        quarter--;
        if (quarter === 0) {
            quarter = 4;
            year--;
        }
    }

    // Reverse to ensure Q1 comes before Q2, Q3, etc.
    options.reverse();
    return options;
}

/**
 * Function to handle Chart.js common configuration options.
 *
 * @param {Object} data Chart data object.
 * @param {string} xLabel Label for the x-axis.
 * @param {string} yLabel Label for the y-axis.
 * @param {boolean} currency Whether to format the y-axis as currency.
 * @param {boolean} hideAxes Whether to hide the axes.
 * @param {boolean} showLegend Whether to show the legend.
 * @param {string} tickScale Time scale for the x-axis.
 * @param {string} color Color for the chart.
 * @param {string} type Type of the chart (line, bar, etc.).
 * @param {string} yAxis Y-axis identifier.
 * @returns {Object} Chart.js configuration options.
 */
export const getChartOptions = (data, xLabel, yLabel, currency = false, hideAxes = false, showLegend = true, tickScale = "quarter", color = OEKOSTROM_COLORS.green, type = "line", yAxis = "y") => ({
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    animation: {
        duration: 0,
    },
    plugins: {
        legend: {
            display: showLegend,
            position: "bottom",
            align: "end"
        },
        tooltip: {
            // Disable default tooltip.
            enabled: false,
            // Use custom tooltip handler.
            external: (context) => externalTooltipHandler(context, tickScale),
            position: "nearest",
            callbacks: {
                label: (tooltipItem) => {
                    const value = tooltipItem.raw.toLocaleString();
                    return currency ? `€${value}` : `${value} k · ${tooltipItem.label}`;
                },
            },
        },
        customTickCircles
    },
    interaction: {
        intersect: false,
        mode: "nearest"
    },
    scales: hideAxes ? undefined : {
        x: {
            type: "category",
            title: { display: false, text: xLabel },
            display: true,
            parsing: false,
            grid: {
                display: false,
            },
            border: {
                display: true,
                color: '#DCDDDD',
                width: 1,
            },
            ticks: {
                source: "labels",
                callback: function (value, index, ticks) {
                    const labels = this.getLabels();  // Get the actual labels array
                    const label = labels[index] || "";  // Fetch the correct label based on index

                    if(typeof label === "string") {
                        if (label.includes("/")) {
                            const [quarter, year] = label.split("/");
                            return [quarter, year];  // Return as an array to create multi-line labels
                        }
                    }

                    // Fallback in case of undefined or incorrect values
                    return label;
                },
            }
        },
        y: {
            display: !hideAxes,
            title: { display: false, text: yLabel },
            beginAtZero: true,
            border: {
                display: false,
                dash: [4, 5],
                color: '#DCDDDD',
            },
            grid: {
                display: true,
                color: '#DCDDDD',
                drawBorder: false,
            },
            ticks: {
                callback: function(value, index, ticks) {
                    const dataset = this.chart?.data?.datasets?.find(ds => ds.yAxisID === yAxis);
                    //console.log('Requested datatype:', dataset?.datatype);
                    switch (dataset?.datatype) {
                        case "currencyLarge":
                            // Make sure value is a number.
                            value = Number(value);
                            value = value / 1000;
                            value = Math.round(value);
                            return '€ ' + value + ' K';
                        case "percentage":
                            return `${value.toLocaleString()}%`;
                        case "none":
                            return value.toLocaleString();
                        default:
                            // Defaults to currency...
                            return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }).replace(/(\d[\d.,]*)\s*€/g, '€ $1');
                    }
                }
            }
        },
        ...(data?.datasets?.some(ds => ds.yAxisID === "yDividends") && {
            yDividends: {
                display: !hideAxes,
                title: { display: false, text: "Dividende/Aktie in EUR" },
                beginAtZero: true,
                position: "right",
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        // Fetch the dataset for yDividends directly
                        const dataset = this.chart?.data?.datasets?.find(ds => ds.yAxisID === "yDividends");
                        switch (dataset?.datatype) {
                            case "percentage":
                                return `${value.toLocaleString()}%`;
                            case "none":
                                return value.toLocaleString();
                            default:
                                return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }).replace(/(\d[\d.,]*)\s*€/g, '€ $1');
                        }
                    }
                }
            }
        })
    }
});

/**
 * Function to generate a Chart.js dataset object.
 *
 * @param {Array} data Data array for the dataset.
 * @param {string} type Type of the dataset (line, bar, etc.).
 * @param {string} label Label for the dataset.
 * @param {string} color Color for the dataset.
 * @param {string} pointBorderColor Color for the point border.
 * @param {string} gradientColour The RGBA array for the gradient.
 * @param {string} default_opacity Default opacity for the gradient.
 * @param {string} datatype Type of data (currency, percentage, etc.).
 * @returns {Object} Chart.js dataset object.
 */
export const getChartDataset = (data, type="line", label="Preis/Aktie in EUR", color = OEKOSTROM_COLORS.green, pointBorderColor = "#ffffff", gradientColour = OEKOSTROM_COLORS.green, default_opacity = '0.2', datatype = "currency") => {
    // Extract RGB values from the color string
    const rgbValues = extractRGBValues(color);
    const { red, green, blue } = rgbValues;

    return {
        type: type,
        label: label,
        fill: type === "line" || type === "bar",
        backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) return `rgba(${red}, ${green}, ${blue}, ${default_opacity})`;

            // Create a transparent gradient...
            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

            // Not quite fully ransparent at the bottom.
            gradient.addColorStop(0, `rgba(${red}, ${green}, ${blue}, 0.1)`);

            // More opaque at the top.
            gradient.addColorStop(1, `rgba(${red}, ${green}, ${blue}, 0.75)`);

            return type === "line" || type === "bar" ? gradient : color;
        },

        // Border configuration
        borderColor: color,
        borderWidth: type === "bar" ? 0 : 3,
        borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomLeft: 0,
            bottomRight: 0
        },
        borderSkipped: false,

        stepped: false,
        data: data || [],

        // Dot configuration
        pointStyle: 'circle',
        pointRadius: type === "line" ? 0 : undefined,
        pointHoverRadius: type === "line" ? 8 : undefined,
        pointHoverBorderWidth: 2,
        pointHoverBorderColor: pointBorderColor,
        pointHitRadius: type === "line" ? 1 : undefined,
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointBorderColor: pointBorderColor,

        lineTension: 0,
        unit: "€",

        datatype: datatype
    };
};

/**
 * Helper function to extract RGB values from an rgba or rgb string.
 *
 * @param colorString
 * @returns {{r: number, g: number, b: number}}
 */
function extractRGBValues(colorString) {
    // Handle rgba format
    let match = colorString.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/);

    if (match) {
        return {
            red: parseInt(match[1], 10),
            green: parseInt(match[2], 10),
            blue: parseInt(match[3], 10)
        };
    }

    // Handle hex format (#RRGGBB)
    match = colorString.match(/#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i);
    if (match) {
        return {
            red: parseInt(match[1], 16),
            green: parseInt(match[2], 16),
            blue: parseInt(match[3], 16)
        };
    }

    // Default if format is not recognized
    console.warn(`Color format not recognized: ${colorString}. Defaulting to black.`);
    return { red: 0, green: 0, blue: 0 };
}
