import moment from 'moment';
import tippy from 'tippy.js/dist/tippy.umd.js';
import "tippy.js/dist/tippy.css";
//import {sharedDataStore} from "./data/sharedDataStore.js";
/*
let sharedData;
sharedDataStore.subscribe((value) => {
    sharedData = value;
});
*/

export const customTickCircles = {
    id: 'customTickCircles',
    afterDraw(chart, args, options) {
        const ctx = chart.ctx;
        const xAxis = chart.scales['x'];
        const yAxis = chart.scales['y'];

        // Determine the grid line color dynamically within the function
        const gridLineColor = '#DCDDDD'; // fallback to black if not set

        // For each tick on the x-axis...
        xAxis.ticks.forEach((tick, index) => {
            const xPos = xAxis.getPixelForTick(index);
            const yPos = yAxis.bottom; // Place it directly on the border line

            // Drawing the circle
            ctx.beginPath();
            ctx.arc(xPos, yPos, 4, 0, 2 * Math.PI); // 3rd parameter is the radius of the circle

            // Fill the center of the circle with white color
            ctx.fillStyle = 'white';
            ctx.fill();

            // Define the border of the circle
            ctx.lineWidth = 1; // Adjust the width of the border as needed
            ctx.strokeStyle = gridLineColor; // Use the grid line color for the circle's border
            ctx.stroke();
        });
    }
};

export const customMarkerPlugin = {
    id: 'customMarkerPlugin',
    afterDraw: (chart, args, options) => {
        if (chart.data.datasets.length === 0) return;

        const dataset = chart.data.datasets[0];

        // Remove old markers
        const oldMarkers = chart.canvas.parentNode.querySelectorAll('.chart-marker');
        oldMarkers.forEach(marker => marker.remove());

        if (!dataset.context || dataset.context.length === 0) return;

        // Get indices for context values that are true
        const contextIndices = dataset.context.map((value, index) => value ? index : -1).filter(index => index !== -1);
        const dataPoints = chart.getDatasetMeta(0).data;

        // Clear old tippy instances
        tippy('[data-tippy-content]').forEach(instance => instance.destroy());

        for (const index of contextIndices) {
            const targetDataPoint = dataPoints[index];
            const position = targetDataPoint.getCenterPoint();
            const dataValue = dataset.context[index];
            const imgElement = document.createElement('img');

            imgElement.className = 'chart-marker';
            //imgElement.src = sharedData.image_url + 'icon_chart.png';
            imgElement.style.position = 'absolute';
            imgElement.style.cursor = 'pointer';
            imgElement.style.left = `${position.x - 36}px`;
            imgElement.style.top = `${position.y - 36}px`;

            // Append the image to the canvas container (or another appropriate container)
            chart.canvas.parentNode.appendChild(imgElement);

            // Attach a tippy tooltip to the image element
            const tippyInstance = tippy(imgElement, {
                placement: 'top',
                duration: 0,           // Instantly show and hide the tooltip
                animation: null,       // Disable any animations
                popperOptions: {
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: ['bottom', 'right', 'left'],
                            },
                        },
                    ],
                },
                onShow(instance) {
                    tippy.hideAll({exclude: instance});
                }
            });

            imgElement.addEventListener('mouseleave', () => {
                tippyInstance.hide();
            });

            const valueRounded = dataset.roundFunction ? dataset.roundFunction(dataset.data[index]) : dataset.data[index];
            const tooltipContent = dataValue + `<div style="margin-top:10px"><span style="white-space: nowrap;"><strong>${valueRounded} ${dataset.unit}</strong> ·</span> ${chart.data.labels[index]}</div>`;

            // Set the content using the instance's setContent method.
            const tooltipInner = document.createElement('div');
            tooltipInner.innerHTML = tooltipContent;
            tippyInstance.setContent(tooltipInner);

            tippyInstance.popper.style.background = 'rgba(56, 59, 58, 1)';
            tippyInstance.popper.style.borderRadius = '8px';
            tippyInstance.popper.style.fontFamily = '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
            tippyInstance.popper.style.fontSize = '14px';
            tippyInstance.popper.style.letterSpacing = '-0.01em';
            tippyInstance.popper.style.lineHeight = '1.5';
        }
    }
};

let tooltipInstance;
let isMobile = window.matchMedia('(max-width: 768px)').matches;

export const externalTooltipHandler = (context, tickScale = "month") => {
    const {chart, tooltip} = context;

    // Identify the scrollable container by class 'isOverflowing'
    let scrollContainer = chart.canvas.closest('.isOverflowing');

    // Initialize tooltip instance if not already initialized
    if (!tooltipInstance) {
        tooltipInstance = tippy(chart.canvas, {
            content: '',
            allowHTML: true,
            placement: 'top',
        });

        // Add a scroll event listener on mobile to hide the tooltip on scroll
        if (isMobile && scrollContainer) {
            scrollContainer.addEventListener('scroll', () => {
                tooltipInstance.hide(); // Hide the tooltip on scroll
            });
        }
    }

    // Hide tooltip if it's not visible
    if (tooltip.opacity === 0) {
        tooltipInstance.hide();
        return;
    }

    // Extract tooltip data
    const titleLines = tooltip.title || [];
    const index = tooltip.dataPoints[0].dataIndex;
    let date, html = '';

    // Set Text
    if (tooltip.body) {
        titleLines.forEach(title => {
            switch (tickScale) {
                case '15':
                    date = moment(title, 'MMM. DD, YYYY, h:mm:ss a').format("DD.MM. HH:mm");
                    break;
                case 'week':
                    date = "KW " + moment(title, 'MMM. DD, YYYY, h:mm:ss a').format('ww');
                    break;
                case 'month':
                    date = moment(title, 'MMM. DD, YYYY, h:mm:ss a').format('MMM YYYY');
                    break;
                default:
                    //date = moment(title, 'MMM. DD, YYYY, h:mm:ss a').format('DD.MM.YYYY');
                    date = title;
            }
        });
    }

    // Construct the HTML content for the tooltip
    let valueRounded = null;
    const dataset = tooltip.dataPoints[0].dataset;
    const datatype = dataset.datatype || null;

    if (datatype === 'currencyLarge') {
        valueRounded = createNeatNumber(dataset.data[index], 2, datatype);
        html = `<span style="white-space: nowrap;"><strong>${valueRounded}</strong> ·</span> ${date}`;
    } else if (datatype === 'currency') {
        valueRounded = createNeatNumber(dataset.data[index], 2, datatype);
        html = `<span style="white-space: nowrap;"><strong>${valueRounded}</strong> ·</span> ${date}`;
    } else if (datatype === 'percentage') {
        valueRounded = createNeatNumber(dataset.data[index], 0, datatype);
        html = `<span style="white-space: nowrap;"><strong>${valueRounded}</strong> ·</span> ${date}`;
    } else {
        valueRounded = createNeatNumber(dataset.data[index], 0, datatype);
        html = `<span style="white-space: nowrap;"><strong>${valueRounded}</strong> ·</span> ${date}`;
    }

    tooltipInstance.setContent(html);

    // Get canvas position to position the tooltip
    const canvasRect = chart.canvas.getBoundingClientRect();
    const padding = 10;

    tooltipInstance.setProps({
        getReferenceClientRect: () => ({
            width: 0,
            height: 0,
            top: canvasRect.top + tooltip.caretY - padding,
            bottom: canvasRect.top + tooltip.caretY,
            left: canvasRect.left + tooltip.caretX,
            right: canvasRect.left + tooltip.caretX,
        })
    });

    // Show the tooltip after re-setting the content and positioning
    tooltipInstance.show();
};

let width, height;

export function getGradient(ctx, chartArea, color) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    let gradient
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, color);
        gradient.addColorStop(0, 'white');
    }

    return gradient;
}

export function filterDataByDateRange(startDate, endDate, labels, values, context = []) {
    let startIndex = labels.indexOf(startDate.split('-').reverse().join('.'));
    let endIndex = labels.indexOf(endDate.split('-').reverse().join('.'));

    if (startIndex === -1 || endIndex === -1) {
        //console.error("Invalid date range!");
        return false;
    }

    return {
        labels: labels.slice(startIndex, endIndex + 1),
        values: values.slice(startIndex, endIndex + 1),
        context: (context.length > 0) ? context.slice(startIndex, endIndex + 1) : false,
    };
}

export function filterIrregularDataByDateRange(startDate, endDate, labels, values, context = []) {
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD").add(1, 'days');

    const binarySearch = (array, targetDate, start, end) => {
        while (start <= end) {
            const mid = Math.floor((start + end) / 2);
            const midDate = moment(array[mid].split(' ')[0], "DD.MM.YYYY");

            if (midDate.isBefore(targetDate)) {
                start = mid + 1;
            } else if (midDate.isAfter(targetDate)) {
                end = mid - 1;
            } else {
                return mid;
            }
        }
        return start; // returns the index of the nearest date that is greater than or equal to targetDate
    };

    const startIndex = binarySearch(labels, start, 0, labels.length - 1);
    const endIndex = binarySearch(labels, end, startIndex, labels.length - 1);

    return {
        labels: labels.slice(startIndex, endIndex),
        values: values.slice(startIndex, endIndex),
        context: context.length > 0 ? context.slice(startIndex, endIndex) : false,
    };
}

export function convertToInputDateFormat(date) {
    return date.split('.').reverse().join('-');
}

let oldDate = moment();

export function ticksCallback(val, index, values, scale = 'month') {
    // Parse the current tick's date
    let currentDate = moment(val);

    // Check if this is the first tick or if the month is different from the previous tick
    switch (scale) {
        case '15':
            // Check if it's the first tick or if the day has changed compared to the previous tick
            if (index === 0 || oldDate.format("DD.MM.YYYY") !== currentDate.format("DD.MM.YYYY")) {
                // If the day has changed, return the date
                oldDate = currentDate;
                return currentDate.format("DD.MM. HH:mm");
            } else {
                // Otherwise, just return the time
                oldDate = currentDate;
                return currentDate.format("HH:mm");
            }
            break;
        case 'day':
            return currentDate.format("DD.MM.");
            break;
        case 'week':
            return "KW " + currentDate.format("ww, DD.MM.") + '-' + currentDate.add(6, 'days').format("DD.MM.");
            break;
        default: // default is month
            if (index === 0 || moment(values[index - 1], "DD.MM.YYYY").month() !== currentDate.month()) {
                // If the month is January, return the month and year
                if (currentDate.month() === 0) {
                    // January is month 0 in moment.js
                    return currentDate
                        .format("MMM YYYY")
                        .replace(".", "");
                }

                // Otherwise, just return the month
                return currentDate.format("MMM").replace(".", "");
            }
    }

    // Hide other tick labels
    return "";
}

export const createNeatNumber = (number, numberOfDecimals = 2, datatype = null) => {
    number = parseNumber(number);
    const rounded = Math.round(number * 100) / 100;

    let formatted;

    if (datatype === "currency" || datatype === "currencyLarge") {
        formatted = new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            currencyDisplay: "narrowSymbol",
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
        }).format(rounded);

        // Ensure Euro symbol is printed before the number!
        formatted = formatted.replace(/(\d[\d.,]*)\s*€/g, "€ $1");
    } else if (datatype === "percentage") {
        formatted = new Intl.NumberFormat("de-DE", {
            style: "decimal",
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
        }).format(rounded) + "%";
    } else {
        formatted = new Intl.NumberFormat("de-DE", {
            style: "decimal",
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
        }).format(rounded);
    }

    return formatted;
};

const parseNumber = (input) => {
    let num =
        typeof input === "string"
            ? parseFloat(input.replaceAll(",", "."))
            : input;
    return isNaN(num) ? 0 : num;
};
